import React, {useContext, useEffect, useState} from 'react';
import './GoogleEmails.scss';
import {Text} from "../../../../modules";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../modules/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    DisconnectGoogleEmailInfoService,
    GetConfluenceSpacesService, GetGoogleEmailInfoService, GetGoogleEmailsListService,
    UpdateGoogleEmailInfoService,
} from "../../../../redux/actions/integrationsActions";
import {GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import {SuccessModalContext} from "../../helpers";

const Info = {
    description: {
        ru: 'Подключите до 3 учетных записей Google Mail, чтобы анализировать электронные письма, связанные с собраниями. Ниже вы можете управлять своими подключенными учетными записями или добавить новую.',
        en: 'Connect up to 3 Google Mail accounts to analyze meeting-related emails. Manage your connected accounts below or add a new one.'
    },
    description_btn: {
        ru: 'Почтовые аккаунты Google еще не подключены. Нажмите "Подключиться", чтобы связать свои учетные записи и начать обработку электронных писем, связанных с собраниями.',
        en: 'Google Mail accounts aren\'t connected yet. Click "Connect" to link your accounts and start processing meeting-related emails.'
    }
}
const GoogleEmails = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [spaces, setSpaces] = useState();
    const confluenceInfo = useSelector(state => state.profile.confluenceInfo);
    const googleEmailInfo = useSelector(state => state.userCalendars?.googleEmailInfo);
    const userCalendars = useSelector(state => state.userCalendars);
    const {handleOnSuccessGoogleEmail} = useContext(SuccessModalContext);
    const googleEmailsList = useSelector(state => state.userCalendars?.googleEmailsList);

    const getSpaces = async () => {
        try {
            const response = await GetConfluenceSpacesService();
            setSpaces(response?.data?.[0])
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (confluenceInfo?.token) {
            getSpaces();
        }
    }, [confluenceInfo])

    const DisconnectEmail = async (item) => {
        try {
            await DisconnectGoogleEmailInfoService({
                provider_id: googleEmailsList?.[0]?.provider_id,
                email: item?.email
            });
            setTimeout(() => {
                dispatch(GetGoogleEmailInfoService());
                dispatch(GetGoogleEmailsListService());
            }, 1000);
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="calendars-block">
            <Text font="medium" size="f18" className="calendars-block--title">{t('google_mail')}</Text>
            {googleEmailInfo?.emails?.length ?
                <>
                    <div className="calendars-block__info">
                        <Text font="liteMedium" size="f16" color="#9898A6">{Info.description[i18n.language]}</Text>
                        <GoogleOAuthProvider clientId={userCalendars?.clientId}>
                            <GoogleEmailLoginButton
                                handleOnSuccess={handleOnSuccessGoogleEmail}
                                t={t}
                            />
                        </GoogleOAuthProvider>

                    </div>
                </>
                :
                <>
                    <div className="calendars-block__top">
                        <Text font="liteMedium" size="f16" color="#9898A6">{Info.description[i18n.language]}</Text>
                    </div>
                    <div className="calendars-block__bottom">
                        <Text font="liteMedium" size="f16" color="#9898A6">{Info.description_btn[i18n.language]}</Text>
                        <GoogleOAuthProvider clientId={userCalendars?.clientId}>
                            <GoogleEmailLoginButton
                                handleOnSuccess={handleOnSuccessGoogleEmail}
                                t={t}
                            />
                        </GoogleOAuthProvider>
                    </div>
                </>
            }
            {googleEmailInfo?.emails?.length ?
                <div className="calendars-block__body">
                    <Text font="liteMedium" size="f16" color="#9898A6">{t('connected_accounts')}</Text>
                    <div className="calendars-block__body--list">
                        {(googleEmailInfo?.emails || [])
                            .map((data, index) => {
                                return (
                                    <div className="calendars-block__body--list-item">
                                        <Text font="liteMedium" size="f16">{data?.email}</Text>
                                        <Button
                                            onClick={() => DisconnectEmail(data)}
                                            text={t('calendar_settings_disconnect')}
                                            variant="ghost"
                                            size="s"
                                            color="red"
                                        />
                                    </div>
                                )
                            })}
                    </div>
                </div>
                : null}
        </div>
    );
};

function GoogleEmailLoginButton({handleOnSuccess, t}) {
    const dispatch = useDispatch();
    const login = useGoogleLogin({
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/gmail.readonly email https://www.googleapis.com/auth/userinfo.profile',
        onSuccess: async (codeResponse) => {
            await UpdateGoogleEmailInfoService({
                scope: codeResponse?.scope,
                code: codeResponse?.code
            });
            handleOnSuccess();
            setTimeout(()=>{
                dispatch(GetGoogleEmailInfoService());
                dispatch(GetGoogleEmailsListService());
            },1000)
            handleOnSuccess();
        },
        onError: errorResponse => console.log(errorResponse),
    });

    return (
        <Button
            onClick={login}
            text={t('connect')}
            size="s"
        />
    );
}

export default GoogleEmails;