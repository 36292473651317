export const USER_CALENDAR_CONSTS = {
    GET_USER_CALENDARS: "GET_USER_CALENDARS",
    UPDATE_USER_CALENDAR: "UPDATE_USER_CALENDAR",
    GET_CALENDAR_EVENTS: "GET_CALENDAR_EVENTS",
    GET_CALENDAR_SYNCHRONIZE: "GET_CALENDAR_SYNCHRONIZE",
    CALENDAR_LOGGED_IN: "CALENDAR_LOGGED_IN",
    CALENDAR_LOG_OUT: "CALENDAR_LOG_OUT",
    GOOGLE_AUTH: "GOOGLE_AUTH",
    GET_GOOGLE_AUTH: "GET_GOOGLE_AUTH",
    OUTLOOK_AUTH: "OUTLOOK_AUTH",
    OUTLOOK_LOG_OUT: "OUTLOOK_LOG_OUT",
    GET_GOOGLE_USER_INFO: "GET_GOOGLE_USER_INFO",
    CLEAR_GOOGLE_USER_INFO: "CLEAR_GOOGLE_USER_INFO",
    GET_GOOGLE_EMAIL_USER_INFO: "GET_GOOGLE_EMAIL_USER_INFO",
    CLEAR_GOOGLE_EMAIL_USER_INFO: "CLEAR_GOOGLE_EMAIL_USER_INFO",
    GET_GOOGLE_EMAILS_LIST_INFO: "GET_GOOGLE_EMAILS_LIST_INFO",
};
