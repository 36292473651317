import React, {useEffect, useState} from 'react';
import {LayoutContainer} from "../../modules/Layout";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import './IntegrationsPage.scss';
import {Text} from "../../modules";
import ServicesList from "./components/ServicesList/ServicesList";
import CalendarInfoBlock from "./components/CalendarInfoBlock/CalendarInfoBlock";
import {SERVICE_TYPES} from "./helpers";
import SuccessModalProvider from "./components/SuccessModal/SuccessModalProvider";
import {GoogleOAuthProvider} from "@react-oauth/google";
import TelegramBlock from "./components/TelegramBlock/TelegramBlock";
import ChatBlock from "../../shared/chat/ChatBlock";
import {isCheckAuth} from "../../utils/helperFunctions";
import ExtensionBlock from "./components/ExtensionBlock/ExtensionBlock";
import SuccessConfluenceModalProvider from "./components/SuccessConfluenseModal/SuccessConfluenseModalProvider";
import ConfluenseBlock from "./components/ConfluenseBlock/ConfluenseBlock";
import {CheckTokenService, UpdateConfluenceInfoService} from "../../redux/actions";
import {ROUTERS_MAP} from "../../utils/routes";
import {useHistory} from "react-router-dom";
import {GetGoogleEmailInfoService, GetGoogleEmailsListService} from "../../redux/actions/integrationsActions";
import GoogleEmails from "./components/GoogleEmails/GoogleEmails";


const IntegrationsPage = () => {
	const {t} = useTranslation();
	const isFullWidth = useSelector(state => state.utils.isFullWidth);
	const [activeTab, setActiveTab] = React.useState(SERVICE_TYPES.GOOGLE);
	const isAuth = isCheckAuth();
	const confluenceToken = localStorage.getItem('confluenceToken')
	const dispatch = useDispatch();
	const history = useHistory();

	const isCheckToken = async () => {
		try{
			const response = await dispatch(CheckTokenService());
			if(response.status === 401){
				history.push(ROUTERS_MAP.LOGIN)
			}
		}catch (e) {
			history.push(ROUTERS_MAP.LOGIN)
			console.error(e)
		}
	}

	useEffect(()=>{
		isCheckToken()
	},[activeTab]);

	useEffect(()=>{
		if(confluenceToken) dispatch(UpdateConfluenceInfoService(JSON.parse(confluenceToken || '{}')));
	},[confluenceToken]);

	useEffect(()=>{
		dispatch(GetGoogleEmailInfoService());
		dispatch(GetGoogleEmailsListService());
	},[])

	return (
		<LayoutContainer isSearch>
			<SuccessConfluenceModalProvider>
			<SuccessModalProvider>
					<div className={`main-container channels-wrap ${isFullWidth ? '' : 'full'}`}>
						<div className="integrations-block chat-anchor">
							<div className="integrations-block__header">
								<Text size="f20" font="medium">{t('integrations')}</Text>
							</div>
							<div className="channels-block__wrap">
								<ServicesList activeTab={activeTab} setActiveTab={setActiveTab}/>
								{[SERVICE_TYPES.GOOGLE, SERVICE_TYPES.OUTLOOK].includes(activeTab) &&
									<CalendarInfoBlock calendarType={activeTab}/>}
								{SERVICE_TYPES.TELEGRAM === activeTab && <TelegramBlock/>}
								{SERVICE_TYPES.EXTENSION === activeTab && <ExtensionBlock/>}
								{SERVICE_TYPES.WORKSPACE === activeTab && <ConfluenseBlock/>}
								{SERVICE_TYPES.EMAIL === activeTab && <GoogleEmails/>}
							</div>
						</div>
					</div>
				{isAuth ? <ChatBlock/> : null}
			</SuccessModalProvider>
			</SuccessConfluenceModalProvider>
		</LayoutContainer>
	)
};

export default IntegrationsPage;