import {
	CalendarLogOut,
	ClearUserInfoGoogleService,
	DeleteOutlookAuth,
	GetAuthorizationUrlOutlook,
	GetCalendarSynchronize, GetGoogleAuth,
	GetUserCalendars, UpdateConfluenceInfoService
} from "../../redux/actions";
import {useDispatch} from "react-redux";
import {googleLogout} from "@react-oauth/google";
import {
	DisconnectGoogleEmailInfoService,
	GetAuthorizationUrlConfluenceService,
	LogoutConfluenceService
} from "../../redux/actions/integrationsActions";

export const useCalendarActions = () => {
	const dispatch = useDispatch();
	const OutlookAuth = async () => {
		try{
			const res = await GetAuthorizationUrlOutlook({
				redirect_to_integrations: true
			});
			window.location.href = res.url;
		}catch (e) {
			console.error(e)
		}
	}

	const OutlookLogOut = async () => {
		try{
			localStorage.removeItem('close-connect-calendar-modal')
			await dispatch(DeleteOutlookAuth());
			await dispatch(GetUserCalendars());
			await dispatch(GetCalendarSynchronize());
		}catch (e) {
			console.error(e)
		}
	}

	async function GoogleLogOut() {
		try{
			googleLogout();
			dispatch(ClearUserInfoGoogleService());
			await dispatch(CalendarLogOut());
			await dispatch(GetGoogleAuth());
			await dispatch(GetUserCalendars());
		}catch (e) {
			console.error(e)
		}
	}

	const GetAuthorizationUrlConfluence = async () => {
		try{
			const res = await GetAuthorizationUrlConfluenceService();
			window.location.href = res.url;
		}catch (e) {
			console.error(e)
		}
	}

	const LogoutConfluence = async () => {
		try{
			await LogoutConfluenceService();
			dispatch(UpdateConfluenceInfoService({}));
			localStorage.removeItem('confluenceToken')
		}catch (e) {
			console.error(e)
		}
	}

	const DisconnectGoogleEmail = async (data) => {
		try{
			(data?.[0]?.emails || []).forEach(async (item)=>{
				await DisconnectGoogleEmailInfoService({
					provider_id: data?.[0]?.provider_id,
					email: item?.email
				})
			});
		}catch (e) {
			console.error(e)
		}
	}

	return {
		OutlookAuth,
		GoogleLogOut,
		OutlookLogOut,
		GetAuthorizationUrlConfluence,
		LogoutConfluence,
		DisconnectGoogleEmail
	};
};