// Import packages
import React, {Suspense, useEffect} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {Provider, useDispatch, useSelector} from "react-redux";
import {persistor, store} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";

// Import assets
import './styles/layout.scss';

// Import Components
import Dashboard from './pages/Dashboard';
import LogIn from "./pages/Authorization/LogIn/LogIn";
import Registration from "./pages/Authorization/Registration/Registration";
import OIDCAuth from "./shared/features/oidc";
import SetTitleComponent from "./shared/features/productName.jsx";
import {ROUTERS_MAP} from "./utils/routes";
import {ManageDataPage} from "./pages/ManageData";
import ToastLayout from "./modules/Toast/Toast";
import {scrollTop} from "./utils/helperFunctions";
import {NotesPage} from "./pages/Notes";
import {IntegrationsPage} from "./pages/Integrations";
import MainPage from "./pages/MainPage/MainPage";
import logIn from "./pages/Authorization/LogIn/LogIn";
function App() {
    const suspenseFallback = (
        <div className="suspense-fallback">
            <p>Loading...</p>
        </div>
    );

    useEffect(()=>{
        scrollTop();
    }, [])


    function detect() {
        let i = new Image();
        i.onload = () => console.log('установлено');
        i.onerror = () => console.log('не установлено или отключено');
        i.src = 'chrome-extension://?id=aeeninnnlhgaojlolnbpljadhbionlal/0.0.88_0/images/no-access.png';
    }

    useEffect(()=>{
        detect();

    }, [])

    return (
        <Suspense fallback={suspenseFallback}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <SetTitleComponent/>
                    <ToastLayout>
                        <Switch>
                            <OIDCAuth>
                            <Route exact strict={true} path="/" component={MainPage} />
                            <Route path={ROUTERS_MAP.LOGIN} component={LogIn}/>
                            <Route path="/registration" component={Registration}/>
                            <Route path="/dashboard" component={Dashboard}/>
                            <Route path={ROUTERS_MAP.NOTES} component={NotesPage}/>
                            <Route path={ROUTERS_MAP.INTEGRATIONS} component={IntegrationsPage}/>
                            <Route strict={true} path={ROUTERS_MAP.MANAGE_DATA} component={ManageDataPage}/>

                                {/*                         <Route path="*"> */}
    {/*                             <Redirect to="/dashboard"/> */}
    {/*                         </Route> */}
                            </OIDCAuth>
                        </Switch>
                    </ToastLayout>
                </PersistGate>
            </Provider>
        </Suspense>
    );
}

export default App;
