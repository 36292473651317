import {Modal} from "antd";
import label from "../../../../image/images/calendar-connect.png";
import labelReauth from "../../../../image/images/calendar-reauth.png";
import {Button} from "../../../../modules/Button";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import './SuccessModal.scss'
import {useDispatch, useSelector} from "react-redux";
import {SuccessModalContext} from '../../helpers';
import {USER_CALENDAR_CONSTS} from "../../../../redux/constants";
import {GetCalendarSynchronize, GetTelegramLinkService, GetUserCalendars, GoogleAuth} from "../../../../redux/actions";
import {useHistory, useLocation} from "react-router-dom";
import {GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import {GetGoogleEmailsListService} from "../../../../redux/actions/integrationsActions";

const getDescription = (i18n, signature) => {
	if (i18n.language === 'ru') {
		return `Ваше имя для участия в видеоконференции "${signature}" было добавлено в ваш профиль.
                        Мы будем использовать его для фильтрации информации о вашем участии в видеороликах.`
	} else {
		return `Your name in video conference "${signature}" has been added to your profile.
                        We will use it to filter information about your participation in videos.`
	}
}

function SuccessModalProvider(props) {
	const {children} = props;
	const {i18n, t} = useTranslation();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showAuthModal, setShowAuthModal] = useState(false);
	const [signature, setSignature] = useState('');
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);
	const isCalendarAddedStorage = localStorage.getItem('close-connect-calendar-modal');
	const isCalendarAdded = searchParams.get('isCalendarAdded');
	const newVCSName = searchParams.get('newVCSName');
	const [telegramLink, setTelegramLink] = useState('');
	const userCalendars = useSelector(state => state.userCalendars);
	const [isGoogleEmail, setIsGoogleEmail] = useState(false);

	async function handleOnSuccess(response) {
		const isCheckScope = (response?.scope || '').includes("https://www.googleapis.com/auth/calendar.readonly")
		if(isCheckScope) {
			dispatch({
				type: USER_CALENDAR_CONSTS.GET_GOOGLE_AUTH,
				payload: response?.hd
			})
			const res = await dispatch(GoogleAuth({
				code: response?.code,
				scope: response?.scope
			}));
			setSignature(res?.added_flag ? res?.signature : '')
			await dispatch(GetCalendarSynchronize());
			await dispatch(GetUserCalendars());
			setShowSuccessModal(true);
		}else{
			setShowAuthModal(true);
		}
	}
	function handleOnSuccessGoogleEmail() {
		setShowSuccessModal(true);
		setIsGoogleEmail(true);
		dispatch(GetGoogleEmailsListService());
	}


	function closeSuccessModal() {
		if(isCalendarAdded === 'true'){
			localStorage.setItem('close-connect-calendar-modal', 'true')
			setShowSuccessModal(false);
			searchParams.delete('isCalendarAdded');
			searchParams.delete('newVCSName');
			history.push({ search: searchParams.toString() });
		}else{
			setShowSuccessModal(false)
			setSignature('')
		}
	}


	useEffect(() => {
		if (isCalendarAdded === 'true' && !isCalendarAddedStorage) {
			setSignature(newVCSName)
			setShowSuccessModal(true);
		}
		getTelegramLink();

		return () => {
			setIsGoogleEmail(false);
		}
	}, [])

	const getTelegramLink = async () =>{
		try{
			const response = await GetTelegramLinkService();
			setTelegramLink(response?.link)
		}catch (e) {
			setTelegramLink('');
			console.error(e)
		}
	}

	return (
		<SuccessModalContext.Provider value={{handleOnSuccess, getTelegramLink, telegramLink, handleOnSuccessGoogleEmail}}>
			{children}
			<Modal
				classNames={{
					wrapper: 'success_modal__wrapper',
				}}
				open={showAuthModal}
				onCancel={()=>setShowAuthModal(false)}
				centered
			>
				<GoogleOAuthProvider clientId={userCalendars?.clientId}>

				<div className="success_modal__container">

					<img src={labelReauth} className="success_modal__container--img"/>
					<div className="success_modal__container--title">
						{t('reauth_calendar_access')}
					</div>
						<div className="success_modal__container--description">
							{t('reauth_calendar_desc')}
						</div>
					{userCalendars?.clientId ?
					<GoogleButton
						t={t}
						handleOnSuccess={handleOnSuccess}
						onClick={()=>{
							setShowAuthModal(false);
						}}
					/>
						: null}
				</div>
				</GoogleOAuthProvider>
			</Modal>
		<Modal
			classNames={{
				wrapper: 'success_modal__wrapper',
			}}
			open={showSuccessModal}
			onCancel={closeSuccessModal}
			centered
		>
			<div className="success_modal__container">

				<img src={label} className="success_modal__container--img"/>
				<div className="success_modal__container--title">
					{isGoogleEmail ? t('google_email_connected_title'): t('calendar_connected_title')}
				</div>
				{(signature && !isGoogleEmail) ?
					<div className="success_modal__container--description">
						{getDescription(i18n, signature)}
					</div>
					: null}
				<Button
					variant="primary"
					size="l"
					text={t('calendar_connected_btn')}
					onClick={closeSuccessModal}
				/>
			</div>
		</Modal>
			</SuccessModalContext.Provider>
	);
}


function GoogleButton({handleOnSuccess, t, onClick}) {
	const login = useGoogleLogin({
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/calendar.readonly',
		onSuccess: async (codeResponse) => {
			handleOnSuccess(codeResponse)
		},
		onError: errorResponse => console.log(errorResponse),
	});
	return (
		<Button
			variant="primary"
			size="l"
			text={t('reauth')}
			onClick={()=>{
				onClick();
				login();
			}}
		/>
	);
}

export default SuccessModalProvider;