import React from 'react';
import {Tooltip} from "antd";
import {InfoCheckIcon, InfoIcon, SettingsIcon} from "../../../image";
import {useTranslation} from "react-i18next";
import './TooltipSettingAutoChains.scss';
import {isDateInPast} from "../../../utils/helperFunctions";

const TooltipSettingAutoChains = (props) => {
	const {data, onClick} = props;
	const {t} = useTranslation();

	if(isDateInPast(data.datetime_end)){
		return (
			<SettingsIcon
				style={{color: data?.video_id ? '#1ABB78' : '#9898A6'}}
				className={"tooltip-settings-auto-chain"} onClick={(e) => {
				if(data?.video_id){
					onClick(e)
				}
			}}/>
		)
	}else if(String(data?.preset?.is_auto) === 'true'){
		return (
			<Tooltip placement="top" title={t('tooltip_auto_settings_chains')}>
				<InfoIcon className={"tooltip-settings-auto-chain"} onClick={() => onClick(data?.id)}/>
			</Tooltip>
		)
	}else if(String(data?.preset?.is_auto) === 'false'){
		return (
			<Tooltip placement="top" title={t('tooltip_manual_settings_chains')}>
				<InfoCheckIcon className={"tooltip-settings-auto-chain"} onClick={() => onClick(data?.id)}/>
			</Tooltip>
		)
	}else{
		return (
			<Tooltip placement="top" title={t('tooltip_not_select_settings_chains')}>
				<InfoIcon className={"tooltip-settings-auto-chain--manual"}  onClick={() => onClick(data?.id)}/>
			</Tooltip>
		);
	}

};

export default TooltipSettingAutoChains;