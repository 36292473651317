export  function objectToQueryString(obj) {
	if (!obj || Object.keys(obj).length === 0) {
		return '';
	}

	return '?' + Object.keys(obj)
		.filter((key)=>obj[key] !== undefined)
		.map((key) => {
			const value = obj[key];
			if (Array.isArray(value)) {
				return `${encodeURIComponent(key)}=${value.join('%20')}`;
			} else {
				return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
			}
		})
		.join('&');
}
