import React from 'react';
import './ActionsBlock.scss';
import Text from "../../../Text/Text";
import {deleteChar, formatTime, ProtocolEditorContext} from "../../helpers";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ActionsBlock = () => {
    const {editor, videoTime} = React.useContext(ProtocolEditorContext);
    const userProfile = useSelector(state => state.profile.userProfile);
    const personId = userProfile?.attached_person?.id;
    const {t} = useTranslation();

    const onHandleClickTime = (e) => {
        editor.commands.insertContent(
            {
                type: 'text',
                text: ` ${formatTime(videoTime)}`,
                marks: [
                    {
                        type: 'custom_tag',
                        attrs: {
                            tag: 'span',
                            class: 'video-time',
                            videoTime: formatTime(videoTime)
                        },
                    }]
            }
        )
        editor.commands.insertContent({
            type: 'text',
                text: ` `,
                marks: [
                {
                    type: 'custom_tag',
                    attrs: {
                        tag: 'span',
                    },
                }]
        });
        e.stopPropagation();
    }


    const onHandleClickPerson = (e) => {
        editor
            .chain()
            .focus()
            .insertNonEditableText({
                // text: `@${userProfile?.first_name} ${userProfile.last_name}:{"person_id": ${personId}, "type": "mention"}`,
                text: ` @${userProfile?.first_name} ${userProfile.last_name}`,
                personName: `${userProfile?.first_name} ${userProfile.last_name}`,
                personId: personId,
                personType: "mention",
            })
            .insertContent(' ')
            .run();
        e.stopPropagation();
    }

    const onHandleClickAssignee = (e) => {
        editor
            .chain()
            .focus()
            .insertNonEditableText({
                // text: `@${userProfile?.first_name} ${userProfile.last_name}:{"person_id": ${personId}, "type": "assignee"}`,
                text: ` @${userProfile?.first_name} ${userProfile.last_name}("assignee")`,
                personName: `${userProfile?.first_name} ${userProfile.last_name}`,
                personId: personId,
                personType: "assignee",
            })
            .insertContent(' ')
            .run();
        e.stopPropagation();
    }


    return (
        <div className="editor__actions">
            <div className="editor__actions--item" onClick={onHandleClickTime}>
                <Text size="f12" font="book">{formatTime(videoTime)}</Text>
            </div>
            <div className="editor__actions--item" onClick={onHandleClickPerson}>
                <Text size="f12" font="book">{t('person').toLowerCase()}: {userProfile?.first_name} {userProfile.last_name}</Text>
            </div>
            <div className="editor__actions--item" onClick={onHandleClickAssignee}>
                <Text size="f12" font="book"> {t('assignee').toLowerCase()}: {userProfile?.first_name} {userProfile.last_name}</Text>
            </div>
        </div>
    );
};

export default ActionsBlock;