export const VIDEOS_CONSTS = {
    GET_VIDEOS: "GET_VIDEOS",
    GET_VIDEOS_CHANNEL: "GET_VIDEOS_CHANNEL",
    CHANGE_API_STATUS_GET_VIDEOS: "CHANGE_API_STATUS_GET_VIDEOS",
    CLEAR_VIDEOS_CHANNEL: "CLEAR_VIDEOS_CHANNEL",
    CLEAR_VIDEOS: "CLEAR_VIDEOS",
    DELETE_VIDEOS: "DELETE_VIDEOS",
    REPROCESS_VIDEOS: "REPROCESS_VIDEOS",
    GET_VIDEO_BY_ID: "GET_VIDEO_BY_ID",
    CLEAR_VIDEO_BY_ID: "CLEAR_VIDEO_BY_ID",
    GET_VIDEOS_SEARCH: "GET_VIDEOS_SEARCH",
    CLEAR_VIDEOS_SEARCH: "CLEAR_VIDEOS_SEARCH",
    UPDATE_VIDEO_CHANNEL: "UPDATE_VIDEO_CHANNEL",
    ADD_VIDEO_DESCRIPTION: "ADD_VIDEO_DESCRIPTION",
    MARK_VIDEO: "MARK_VIDEO",
    UNMARK_VIDEO: "UNMARK_VIDEO",

    SHARE_VIDEO: "SHARE_VIDEO",

    GET_SERVICE_STATUSES: "GET_SERVICE_STATUSES",
    SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
    ADD_CHAIN_TO_VIDEO: "ADD_CHAIN_TO_VIDEO",
    DELETE_CHAIN_TO_VIDEO: "DELETE_CHAIN_TO_VIDEO",
    ADD_CHAIN_TO_VIDEOS: "ADD_CHAIN_TO_VIDEOS",
    CHANGE_VIDEO_TAB: "CHANGE_VIDEO_TAB",
};
