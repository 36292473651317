import {_urlChat, _urlExtension, request} from "../api";
import {UTIL_CONSTS} from "../constants/utilConsts";
import {CHAT_CONSTS} from "../constants/chatConsts";
import {HIGHLIGHT_CONSTS, VIDEOS_CONSTS} from "../constants";
import {FILTERS_OR_SORT_CONSTS} from "../constants/filtersOrSort";
import {objectToQueryString} from "../../utils/api";

export const GetMessagesService = (params) => {
	let requestData = {
		url: `${_urlChat}${objectToQueryString(params)}`,
		method: "GET",
	};

	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				return res?.data;
			})
	};
};

export const SendMessageService = (data) => {
	let requestData = {
		url: `${_urlChat}`,
		method: "POST",
		data
	};

	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				return res?.data;
			})
	};
};


export const UpdateOpenChat = () => {
	return dispatch => {
		dispatch({
			type: CHAT_CONSTS.UPDATE_OPEN_CHAT,
		})
	}
};


export const GetChatSessionsService = (params) => {
	let requestData = {
		url: `${_urlChat}/sessions${objectToQueryString(params)}`,
		method: "GET",
	};

	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				console.log('sdadsa222', res?.data);

				await dispatch({
					type: CHAT_CONSTS.GET_SESSIONS,
					payload: res?.data
				});
					return res?.data
			})
	}
};


export const CreateChatSessionService = (data = {}) => {
	const requestData = {
		url: `${_urlChat}/sessions`,
		method: "POST",
		data
	};
	return request(requestData)
		.then(async (res) => {
			return res?.data
		})
};

export const UpdateSelectedIdSessionService = (id) => {
	console.log('QEWRR', id)
	return dispatch => {
		dispatch({
			type: CHAT_CONSTS.UPDATE_SELECTED_ID_SESSION,
			payload: id
		})
	}
}

export const UpdateGlobalChatService = (data) => {
	return dispatch => {
		dispatch({
			type: CHAT_CONSTS.UPDATE_GLOBAL_CHAT,
			payload: data
		})
	}
}


export const UpdateChatSessionService = (id, data) => {
	const requestData = {
		url: `${_urlChat}/sessions/${id}`,
		method: "PATCH",
		data
	};
	return request(requestData)
		.then(async (res) => {
			return res?.data
		})
};