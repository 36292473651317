import {PROTOCOL_CONSTS, VIDEOS_CONSTS} from "../constants";

export const initialState = {
    protocolList: [],
    isEditProtocolId: undefined,
    createNewProtocolId: undefined
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROTOCOL_CONSTS.GET_PROTOCOL:
            return {
                ...state,
                protocolList: action.payload,
            };
        case PROTOCOL_CONSTS.ADD_PROTOCOL:
            let arr = state.protocolList
            if (!!Number.isInteger(action.payload.protocolIndex)) {
                arr.splice(action.payload.protocolIndex + 1, 0, action.payload.data)
            } else {
                arr = [...arr, action.payload.data]
            }
            return {
                ...state,
                protocolList: [...arr],
            };
        case PROTOCOL_CONSTS.ADD_PROTOCOL_HIGHLIGHTS:
            return {
                ...state,
                protocolList: state.protocolList.map(item => item?.id === action.payload ? {
                    ...item,
                    marked: true
                } : item),
            };
        case PROTOCOL_CONSTS.UPDATE_PROTOCOL:
            return {
                ...state,
                protocolList: state.protocolList.map(item => item?.id === action.payload.id ? {
                    ...item,
                    ...action.payload.data,
                    isMarked: !!action.payload.data?.start_time
                } : item),
            };
        case PROTOCOL_CONSTS.DELETE_PROTOCOL_HIGHLIGHTS:
            return {
                ...state,
                protocolList: state.protocolList.map(item => item?.id === action.payload ? {
                    ...item,
                    marked: false
                } : item),
            };
        case PROTOCOL_CONSTS.DELETE_PROTOCOL:
            return {
                ...state,
                // protocolList: state.protocolList.map(item => {
                //
                //     if(item.id === action.payload?.id){
                //         return null
                //     }
                //     return item
                // }),
                protocolList: state.protocolList.filter(item => item.id !== action.payload?.id),
            };
        case PROTOCOL_CONSTS.TOGGLE_EDIT_FLAG:
            return {
                ...state,
                isEditProtocolId: action.payload,
            };
        case VIDEOS_CONSTS.MARK_VIDEO:
            return {
                ...state,
                protocolList: state.protocolList.map(item => {
                    return Number(action.payload?.start_time) === Number(item.start_time)
                    && (action.payload?.person_id === item?.person_id) && item.start_time !== null ? {
                        ...item,
                        isMarked: true
                    } : item
                })
            };
        case VIDEOS_CONSTS.UNMARK_VIDEO:
            return {
                ...state,
                protocolList: state.protocolList.map(item =>{
                    return Number(action.payload?.start_time) === Number(item.start_time)
                    && (action.payload?.person_id === item?.person_id) ? {
                        ...item,
                        isMarked: false
                    } : item
                })
            };
        case PROTOCOL_CONSTS.TOGGLE_CREATE_NEW_PROTOCOL:
            return {
                ...state,
                createNewProtocolId: action.payload
            };
        default:
            return state;
    }
}
