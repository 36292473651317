import {Modal} from "antd";
import label from "../../../../image/images/calendar-connect.png";
import {Button} from "../../../../modules/Button";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import './SuccessConfluenseModal.scss'
import {useDispatch} from "react-redux";
import {
    UpdateConfluenceInfoService
} from "../../../../redux/actions";
import {useLocation} from "react-router-dom";
import {
    GetAuthorizationStateConfluenceService,
} from "../../../../redux/actions/integrationsActions";
import labelMistake from "../../../../image/images/mistake_connection.png";
import {Text} from "../../../../modules";
import {useCalendarActions} from "../../hooks";

function SuccessConfluenceModalProvider(props) {
    const {children} = props;
    const {t, i18n} = useTranslation();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isStateQuery = searchParams.get('state');
    const isCodeQuery = searchParams.get('code');
    const [violations, setViolations] = useState(true);
    const [data, setData] = useState({});
    const {GetAuthorizationUrlConfluence} = useCalendarActions();

    function closeSuccessModal() {
        setShowSuccessModal(false);
    }

    const AuthorizationTwoStep = async () => {
        try {
            if (isStateQuery && isCodeQuery) {
                const response = await GetAuthorizationStateConfluenceService(isStateQuery, isCodeQuery);
                setData(response)
                setViolations(response?.no_violations);
                setShowSuccessModal(true);
                if (response?.token && response?.no_violations) {
                    localStorage.setItem('confluenceToken', JSON.stringify(response || {}));
                    dispatch(UpdateConfluenceInfoService(response));
                }
            } else {
                // const response = await GetConfluenceInfoService()
                // console.log('asdas',response)
                // if (response?.token) {
                // 	setShowSuccessModal(true);
                // 	localStorage.setItem('confluenceToken', response?.token);
                // }
            }
        } catch (e) {

        }
    }


    useEffect(() => {
        AuthorizationTwoStep();
    }, [])


    return (
        <div>
            {children}
            <Modal
                classNames={{
                    wrapper: 'success_modal__wrapper',
                }}
                open={showSuccessModal}
                onCancel={closeSuccessModal}
                centered

            >
                <div className="success_modal__container">
                    {
                        violations ?
                            <img src={label} className="success_modal__container--img"/>
                            :
                            <img src={labelMistake} width={"256px"} height={"240px"}
                                 className="success_modal__container--img reauth"/>
                    }

                            <>
                                <div className="success_modal__container--title">
                                    {!violations ? t('oops_something') : t('confluence_connected_title')}
                                </div>
                                    {violations ?
                                        null
                                        :
                                        <div className="success_modal__container--title">
                                            <Text size="f14" font="liteMedium" as="span"
                                                  color="#9898A6">{t('no_violations_text_1')} <a target="_blank"
                                                                                                 href={data?.url}>{data?.url}</a>.</Text>
                                            <Text size="f14" font="liteMedium" as="p"
                                                  color="#9898A6">{t('no_violations_text_2')}</Text>
                                            <ol style={{margin: '5px 0'}}>
                                                <Text size="f14" font="liteMedium" as="li"
                                                      color="#9898A6">{t('no_violations_text_3')}</Text>
                                                <Text size="f14" font="liteMedium" as="li"
                                                      color="#9898A6">{t('no_violations_text_4')}</Text>
                                                <Text size="f14" font="liteMedium" as="li"
                                                      color="#9898A6">{t('no_violations_text_5')}</Text>
                                            </ol>
                                            <Text size="f14" font="liteMedium" as="p" color="#9898A6">
                                                {t('no_violations_text_6')}{' '}
                                                <Text font="liteMedium" size="f14" color="#9898A6"><a
                                                    href="mailto:ponomarev@secretopus.ai?subject=Add%20multiple%20links%20for%20the%20confluence">{i18n.language === 'en' ? 'Contact us' : 'Связаться с нами'}</a>{i18n.language === 'en' ? ' for assistance!' : ' прошу помощи!'}
                                                </Text>
                                            </Text>
                                        </div>
                                    }
                            </>

                                {violations ? <Button
                                        variant="primary"
                                        size="l"
                                        text={t('calendar_connected_btn')}
                                        onClick={closeSuccessModal}
                                    />
                                    :
                                    <div className="success_modal__container--buttons">
                                        <Button
                                            text={t('button_cancel')}
                                            variant="secondary"
                                            onClick={closeSuccessModal}
                                        />
                                        <Button
                                            variant="primary"
                                            size="l"
                                            text={t('copy_try_again')}
                                            onClick={GetAuthorizationUrlConfluence}
                                        />
                                    </div>
                                }

                </div>
            </Modal>
        </div>
    );
}

export default SuccessConfluenceModalProvider;