import React from 'react';
import './PersonalVideos.scss';
import {LockIcon} from "../../../../../image";
import VideoBlock from "../VideoBlock/VideoBlock";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {generateChannelsList} from "../../../../../shared/features/Chains/helpers";
import ChainsDropDown from "../../../../../shared/features/Chains/ChainsDropDown/ChainsDropDown";
import {ROUTERS_MAP} from "../../../../../utils/routes";
import {Popover} from "antd";


const PersonalVideos = (props) => {
    const {videos, personalBadgeInfo} = props;
    const channels = useSelector(state => state?.channels?.channels || [])
    const badge = channels.find((item) => item.is_private && (item.name === 'Личное' || item?.name === 'Personal' || item?.name === "Private"));
    const {t} = useTranslation();
    const arr = (videos || []).slice(0, 4);
    const channelsList = generateChannelsList(channels);
    const chainsList = useSelector(state => state.chains?.chainsList || []);


    if (arr.length < 1) {
        return null;
    }

    return (
        <div className="personal-videos">
            <div className="personal-videos__header">
                <h2 className="personal-videos__header--title">{personalBadgeInfo?.name}</h2>
                <LockIcon className="personal-videos__header--icon"/>
              <Popover
                arrow={false}
                trigger='click'
                placement="rightTop"
                overlayClassName='chains-menu'
                destroyTooltipOnHide={true}
                content={
                  <ChainsDropDown
                    chains={badge?.chains || []}
                    channel={badge}
                    isAll={true}
                    channelStyle={channelsList?.[badge?.id]}
                  />
                }
                >
                 <span className="count">
                               {badge?.chains?.length || 0} {t('Chains')}
                            </span>
              </Popover>

                <div className="personal-videos__header--link">
                  <Link
                    to={{
                      pathname: `${ROUTERS_MAP.CHANNEL}/${personalBadgeInfo.id}`,
                      state: {
                        routs: [
                          {
                            path: ROUTERS_MAP.KNOWLEDGE_DATA,
                            name: t('menu_item_knowledge')
                          }
                        ]
                      }
                    }}>
                        {t('see_all')}&nbsp;({badge?.videos_count})
                    </Link>
                </div>
            </div>
            <div className="personal-videos__list">
                {arr.map((video) => (
                    <VideoBlock
                        video={video}
                        badge={badge}
                        channelsList={channelsList}
                        chainList={chainsList}
                        isPreview={true}
                        isChaneType={true}
                        isDetach={true}
                        routs={[
                            {
                                path: '/dashboard/videos/review',
                                name: t('menu_item_knowledge')
                            },
                            {
                                path: `${ROUTERS_MAP.CHANNEL}/${personalBadgeInfo.id}`,
                                name: personalBadgeInfo?.name
                            }
                        ]}
                    />
                ))}
            </div>
        </div>
    );
};

export default PersonalVideos;