// Import packages
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";

// Import assets
import './channels.scss';

// Import Components
import Section from "./section/Section";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    ChangeHeaderTitle,
    GetVideos,
    GetPersons,
    ClearVideoList,
    GetVideosService, ResetFiltersAction
} from "../../../../redux/actions";
import {GetChannels} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import Calendar from "../../../../shared/features/calendar/Calendar";
import {hasPermission} from "../../../../utils/helperFunctions";
import {Loader} from "../../../../shared/features/Loader";
import RecentVideo from "./RecentVideo/RecentVideo";
import PersonalVideos from "./PersonalVideo/PersonalVideos";
import dayjs from "dayjs";
import i18n from "i18next";
import {getChains} from "../../../../redux/actions/chainsActions";
import {generateChannelsList} from "../../../../shared/features/Chains/helpers";
import {ApiStatus, ROLE_ITEMS} from "../../../../utils/constants";
import FiltersAll from "../../../../modules/Filters/components/FiltersAll/FiltersAll";
import {FILTER_SORT_PAGE_TYPES, getValueFilters} from "../../../../modules/Filters/helpers";
import FiltersList from "../../../../modules/Filters/components/FiltersList/FiltersList";
import {VIDEOS_CONSTS} from "../../../../redux/constants";
import {LayoutContainer} from "../../../../modules/Layout";

function Channels(props) {
    const [channelsVideos, setChannelsVideos] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const channels = useSelector(state => state.channels?.channels || []);
    const channelsList = generateChannelsList(channels);
    const isFetchingVideos = useSelector(state => state.videos.isFetchingVideos);
    const allFilters = useSelector(state => state.filtersOrSort);
    const allFiltersValue = getValueFilters(allFilters,FILTER_SORT_PAGE_TYPES.KNOWLEDGE_DATA);
    const filters = useSelector(state => state.filtersOrSort?.[FILTER_SORT_PAGE_TYPES.KNOWLEDGE_DATA].filters?.selectedFilters || []);

    const getVideos = async (responseChannels) => {
        try {
            dispatch({
                type: VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
                payload: ApiStatus.LOADING
            })
            const response = await dispatch(GetVideosService({
                limit: 10,
                channel_ids: (responseChannels || []).map((item) => item?.id),
                use_video_limit_per_channel: true,
                ...allFiltersValue,
            }, true));
            dispatch({
                type: VIDEOS_CONSTS.GET_VIDEOS_CHANNEL,
                payload: response
            })
            dispatch({
                type: VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
                payload: ApiStatus.SUCCESS
            })
        } catch (error) {
            console.log(error)
            dispatch({
                type: VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
                payload: ApiStatus.ERROR
            })
        }
    }
    const getContent = async () => {
        try {
            setIsLoading(true);
            const responseChannels = await dispatch(GetChannels());
            await getVideos(responseChannels);
            props.getChains();
            const title = t('menu_item_knowledge');
            props.ChangeHeaderTitle(title)
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.warn(e);
        }
    }

    useEffect(() => {
        getContent();
    }, []);

    useEffect(() => {
        clearVideo();
        dispatch(ResetFiltersAction({
            pageType: FILTER_SORT_PAGE_TYPES.KNOWLEDGE_DATA
        }));
        dispatch({
            type: VIDEOS_CONSTS.CLEAR_VIDEOS_CHANNEL,
            payload: {}
        });
    }, [])

    const clearVideo = async () => {
        await props.ClearVideoList();
    }

    useEffect(() => {
        if (props?.channels?.length) {
            let list = {};
            (props?.channels || []).forEach(item => {
                list = {
                    ...list,
                    [item.id]: props?.videosList?.results?.filter(i => i.channels.includes(item.id))
                }
            })
            setChannelsVideos(list)
        }
    }, [props.videosList, props?.channels]);

    const noChannelList = !!props?.videosList?.results?.length && props?.videosList?.results?.filter(i => !i?.channels?.length)



    let x = i18n.language === 'en' ? "Personal" : "Личное";
    let newList = props?.channels?.sort((a, b) => {
        if (a.is_private) {
            return -1;
        }
        if (b.id === x) {
            return 1;
        }
        return 0;
    });
    const recentVideos = (props?.videosList?.results || []);

    const personalBadgeInfo = (props?.channels || []).find((item) => ['Личное', 'Personal', 'Private'].includes(item?.name));
    const personalVideos = (channelsVideos?.[personalBadgeInfo?.id] || []);
    const videosCount = Object.values(channelsVideos || {}).filter((item) => item?.length > 0).length;
    const test = Object.values(channelsVideos || {});

    return (
        <LayoutContainer isSearch className="channels-layout-container">
            <div className={`main-container channels-container ${props.isFullWidth ? '' : 'full'}`}>
                <div className="channels-wrap">
                    <div className="channels">
                        <div className="channels__header">
                            <h5 className="page-title">
                                {t('menu_item_knowledge')}
                            </h5>
                            <FiltersAll pageType={FILTER_SORT_PAGE_TYPES.KNOWLEDGE_DATA}/>
                        </div>
                        <div className="channels__filters-list">
                            <FiltersList pageType={FILTER_SORT_PAGE_TYPES.KNOWLEDGE_DATA}/>
                        </div>
                        {(isFetchingVideos === ApiStatus.LOADING) ?
                            <div className="loader-wrap">
                                <Loader siz={50}/>
                            </div>
                            :
                            <>
                                {videosCount > 0 ?
                                    <>
                                        {(props?.videosList?.results || [])?.length > 0 ?
                                            <div className="recent-videos">
                                                <h2 className="recent-videos--title">{t('recent_channel')}</h2>
                                                <div className="recent-videos__list">
                                                    {(props?.videosList?.results || []).slice(0, 5).map((item, index) => (
                                                        <RecentVideo
                                                            key={index}
                                                            channelsList={channelsList}
                                                            video={item}
                                                            isPreview={true}
                                                            routs={[
                                                                {
                                                                    path: '/dashboard/videos/review',
                                                                    name: t('menu_item_knowledge')
                                                                },
                                                                {
                                                                    path: '/dashboard/videos/review',
                                                                    name: t('menu_item_knowledge')
                                                                },
                                                                {
                                                                    path: '/dashboard/videos/review',
                                                                    name: t('recent_channel')
                                                                }
                                                            ]}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            : null
                                        }
                                        <PersonalVideos
                                            personalBadgeInfo={personalBadgeInfo}
                                            videos={personalVideos}
                                        />

                                        {!!newList?.length && newList?.filter((item) => item?.id !== personalBadgeInfo?.id).map((badge) => {
                                            return !!channelsVideos?.[badge?.id]?.length &&
                                                <Section key={badge?.id}
                                                         badge={badge}
                                                         list={channelsVideos?.[badge?.id]}/>
                                        })}
                                        {!!noChannelList?.length && <Section badge={{name: t('no_channels')}}
                                                                             list={noChannelList}/>}
                                    </>
                                    :
                                    <>
                                        {filters.length ?
                                            <div className="empty-info">
                                                <span className="empty-info--text">{t('empty_list_videos_text_1')}</span>
                                                <span className="empty-info--text">{t('empty_list_videos_text_2')}</span>
                                            </div>
                                            :
                                            <div className={`channels__section-videos-empty`}>
                                                {t('empty_list')}
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                    {hasPermission(ROLE_ITEMS.SELF_PROFILE_CALENDAR_EDIT) && isFetchingVideos !== ApiStatus.INITIAL && <Calendar/>}
                </div>
            </div>
        </LayoutContainer>

    )


}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'channels',
        'videosList',
        'isFullWidth',
        'userRoles'
    ])
};

const mapDispatchToProps = {
    GetVideos,
    ChangeHeaderTitle,
    GetPersons,
    ClearVideoList,
    getChains
};

export default connect(mapStateToProps, mapDispatchToProps)(Channels);
