import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import dayjs from "dayjs";
import {
    ArrowTopCircleIcon, BurgerIcon,
    ChatIcon,
    CleanIcon,
    CopyIcon, EditBoxIcon,
    ErrorIcon, Octopus2Icon,
    OctopusIcon,
    PlusIcon,
    SearchIcon, SuccessIcon
} from "../../../../image";
import './MessageBlock.scss';
import {TABS} from "../AiChats/AiChats";
import {useDispatch, useSelector} from "react-redux";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {Loader} from "../../../features/Loader";
import {Tooltip} from "antd";
import {
    CreateChatSessionService,
    GetChatSessionsService,
    GetMessagesService,
    SendMessageService, UpdateChatSessionService,
    UpdateGlobalChatService, UpdateSelectedIdSessionService
} from "../../../../redux/actions/chatActions";
import {getDateOpenChat, getTimeDifference, getTokensText, preparedMessages} from "../../helpers";
import {MarkDownText} from "../../../features/MarkDownText";
import {useTranslation} from "react-i18next";
import SourcesBlock from "../SoursecBlock/SourcesBlock";
import {Form, Toast} from "react-bootstrap";
import {copyToClipboard, getHeaderHeight, isCheckAuth} from "../../../../utils/helperFunctions";
import {useLocation} from "react-router-dom";
import {monthsShort, monthsShortEn} from "../../../../utils/constants";
import {io} from "socket.io-client";
import {HOST_NAME} from "../../../../redux/api";
import {Text} from "../../../../modules";

const DefaultMessages = (i18n) => [
    {
        response_text: i18n.language === 'en'
            ? "Hi! I'm like GPT but secure and connected to your corporate data  🤖<br><br>" +
            "💡 You can ask me about meeting outcomes/tasks/Confluence docs/etc., or make complex queries with company context. Let's try! 🚀"
            : "Привет! Я похож на GPT, но защищен и подключен к вашим корпоративным данным 🤖<br><br>" +
            "💡 Вы можете спрашивать меня о результатах совещаний / задачах / документах Confluence и т.д. Или задавать сложные запросы в контексте компании. Давайте попробуем! 🚀"
    },
];

const MessageBlock = (props) => {
    const location = useLocation();
    const {activeTab, setActiveTab, isOpenPopover,onClickBurger,containerHeight} = props;
    const [text, setText] = useState('');
    const userInfo = useSelector(state => state.profile.userProfile);
    const userAvatar = useSelector(state => state.profile.userAvatar);
    const videoInfo = useSelector(state => state.videos.videoById);
    const listRef = useRef(null);
    const textAreaRef = useRef(null);
    const [isLoadingMessage, setIsLoadingMessage] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
    const checkVideoPage = (location.pathname || '').includes('/dashboard/videos/video');
    const [messages, setMessages] = useState(preparedMessages(DefaultMessages(i18n, checkVideoPage), [], userInfo));
    const isCheckMessages = messages.length <= 1;
    const [chatVideoType, setChatVideoType] = useState(false);
    const isGlobalChat = useSelector(state => state.chat.isGlobalChat);
    const videoId = (!isGlobalChat && checkVideoPage) ? (location.pathname || '').split('/')?.at(-1) : null;
    const [sessionId, setSessionId] = useState();
    const [responseData, setResponseData] = useState();
    const [logicalMessages, setLogicalMessages] = useState();
    const token = localStorage.getItem('jwtToken');
    const isLoadingRef = useRef(false);
    const logicalMessagesRef = useRef([]);
    const [isLoading, setIsLoading] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);
    const selectedIdSession = useSelector(state => state.chat.selectedIdSession);
    const [sendStatus, setSendStatus] = useState(false);
    const isAuth = isCheckAuth();

    useEffect(() => {
        if (!sendStatus) {
            getMessages();
        }
    }, [selectedIdSession]);

    useEffect(() => {
        isLoadingRef.current = isLoading; // Обновляем ref при каждом изменении состояния
    }, [isLoading]);
    useEffect(() => {
        logicalMessagesRef.current = logicalMessages; // Обновляем ref при каждом изменении состояния
    }, [logicalMessages]);

    useEffect(() => {
        const value = window.localStorage.getItem('chat_type');
        // if(chatVideoType !== Boolean(value)){
        // 	setChatVideoType(Boolean(value === !!Number(videoId)))
        // }
        if (isOpenPopover) {
            setChatVideoType(!Number(videoId))
        } else {
            window.localStorage.removeItem('chat_type')
        }
    }, [isOpenPopover]);

    const changeText = (e) => {
        setText(e?.target?.value || '')
    }

    const sendMessage = async (text) => {
        try {
            if (!responseData?.session?.is_active) {
                setSendStatus(true)
                const data = await CreateChatSessionService(isGlobalChat ? {} : {video_id: Number(videoId)});
                await dispatch(GetChatSessionsService({
                    video_id: isGlobalChat ? undefined : Number(videoId)
                }));
                dispatch(UpdateSelectedIdSessionService(data?.id));
                setMessages([...preparedMessages(DefaultMessages(i18n, checkVideoPage), [], userInfo),
                    {
                        id: 100,
                        isBot: false,
                        text,
                        date: dayjs(),
                        used_db_query: false,
                        author_name: `${userInfo?.last_name || ''} ${userInfo?.first_name || ''}`,
                    }
                ]);

                setTimeout(async ()=>{
                    setError(null);
                    setText('');
                    setIsLoadingMessage(true);
                    setIsLoading(true);
                    setLogicalMessages([]);
                    textAreaRef.current.style.height = '20px';
                    await dispatch(SendMessageService({
                        video_id: videoId ? Number(videoId) : undefined,
                        request_text: text,
                        clear_session: false,
                        session_id: data?.id
                    }));
                    const response = await dispatch(GetMessagesService({
                        video_id: videoId || undefined,
                        session_id: data?.id
                    }));
                    setResponseData(response || {})
                    setMessages(preparedMessages(DefaultMessages(i18n, checkVideoPage), response?.chat || [], userInfo));


                    setIsLoadingMessage(false);
                    setIsLoading(false);
                },1000);
                setSendStatus(false)
            }else{
                addText(text, selectedIdSession);
            }
        }catch (e) {
            setSendStatus(false);
            console.error(e)
        }
    }

    const addText = async (text) => {
        try {
                if (text) {
                    setError(null);
                    setText('');
                    console.log('MESS', responseData)
                    setMessages([...messages, {
                        id: 100,
                        isBot: false,
                        text,
                        date: dayjs(),
                        used_db_query: false,
                        author_name: `${userInfo?.last_name || ''} ${userInfo?.first_name || ''}`,
                    }]);
                    setIsLoadingMessage(true);
                    setIsLoading(true);
                    setLogicalMessages([]);
                    textAreaRef.current.style.height = '20px';

                    await dispatch(SendMessageService({
                        video_id: videoId ? Number(videoId) : undefined,
                        request_text: text,
                        clear_session: false,
                        session_id: selectedIdSession
                    }));
                    const response = await dispatch(GetMessagesService({
                        video_id: videoId || undefined,
                        session_id: selectedIdSession
                    }));
                    setResponseData(response || {})
                    setMessages(preparedMessages(DefaultMessages(i18n, checkVideoPage), response?.chat || [], userInfo));

                    textAreaRef.current.style.height = '20px';
                    setIsLoadingMessage(false);
                    setIsLoading(false);
                }
        } catch (e) {
            setError(e);
            setIsLoading(false);
            setIsLoadingMessage(false)
        }
    }

    const copyMessage = (item) => {
        try {
            copyToClipboard(item.text)
            setToastInfo({
                isShow: true,
                type: 'success',
                text: t("toast_success_description")
            });
        } catch (e) {
            console.error(e)
        }
    }

    const getMessages = async () => {
        try {
            const response = await dispatch(GetMessagesService({video_id: videoId || undefined, session_id: selectedIdSession}));
            setResponseData(response || {})
            setLogicalMessages([]);
            setMessages(preparedMessages(DefaultMessages(i18n, checkVideoPage), response?.chat || [], userInfo))
        } catch (e) {
            setError(e);
            console.error(e);
        }
    }

    const clearChat = async () => {
        try {
            if (!isCheckMessages && !isLoadingMessage) {
                setMessages(preparedMessages(DefaultMessages(i18n, checkVideoPage), [], userInfo));
                setLogicalMessages([]);
                const data = await CreateChatSessionService(isGlobalChat ? {} : {video_id: videoInfo?.id});
                const response = await dispatch(GetMessagesService({
                    video_id: isGlobalChat ? undefined :videoId,
                    session_id: data?.id
                }));
                dispatch(UpdateSelectedIdSessionService(data?.id));
                setResponseData(response || {})
            }
        } catch (e) {
            setError(e);
            console.error(e);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            // Если нажата клавиша Enter без Shift
            if (!e.shiftKey) {
                e.preventDefault(); // Предотвращаем перенос строки
                if (!isLoadingMessage) sendMessage(text);
            }
        }
    };

    useEffect(() => {
        function updateHeightTextArea() {
            this.style.height = '20px';
            this.style.overflow = 'hidden';
            this.style.height = this.scrollHeight + 'px';
        }

        textAreaRef.current?.addEventListener('input', updateHeightTextArea);
        setHeaderHeight(getHeaderHeight())
        return () => {
            textAreaRef.current?.removeEventListener('input', updateHeightTextArea);

        }
    }, []);


    useLayoutEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [messages, isLoadingMessage, logicalMessages?.length]);

    useEffect(() => {
        if (activeTab === TABS.MESSAGE ) {
            getMessages();
        }
    }, [chatVideoType]);


    const updateGlobalChat = async (value) => {
        try {
            dispatch(UpdateGlobalChatService(value));
            const response = await dispatch(GetChatSessionsService({
                video_id: value ? undefined : videoInfo?.id
            }));
            dispatch(UpdateSelectedIdSessionService(response?.[0]?.id));
            if(!response?.[0]?.is_read) UpdateChatSessionService(response?.[0]?.id,{is_read: true});

            window.localStorage.setItem('chat_type', value);

        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const socket = io(`${HOST_NAME}/document_updates`)

        if (isAuth) {
			// Обрабатываем ошибку подключения
            socket.on('connect_error', function (error) {
                console.error('Не удалось подключиться:', error);
            });
            socket.on("message", (data) => {
                console.log("Получено сообщение:", data);
            });
            // Обрабатываем отключение от сервера
            socket.on('disconnect', function (reason) {
                console.log('Отключено от сервера:', reason);
            });

            // Слушаем события 'update' и выводим их в консоль
            socket.on('update', function (data) {
                if (data?.update_type === "update" && data?.object_type === "chat" && isLoadingRef.current) {
                    setTimeout(() => {
                        setLogicalMessages(prevMessages => {
                            const newMessages = [...prevMessages, data?.content];

                            // Дождаться обновления перед скроллом
                            requestAnimationFrame(() => {
                                if (listRef.current) {
                                    listRef.current.scrollTop = listRef.current.scrollHeight;
                                }
                            });

                            return newMessages;
                        });
                    }, 500);
                }
                console.log('Получено обновление:', data);
            });
            // Обрабатываем событие подключения
            socket.on('connect', function () {
                console.log('Подключено к серверу');

                // Присоединяемся к комнате 'video_1000' и передаем токен
                socket.emit('join_room', {
                    token: token,
                    room: `chat-session_${selectedIdSession}`
                }, function (response) {
                    if (response && response.error) {
                        console.error('Ошибка при присоединении к комнате:', response.error);
                    } else {
                        console.log(`Присоединились к комнате chat-session_${selectedIdSession}`);
                    }
                });
            });
        }
        // Clean up on component unmount
        return () => {
            socket.disconnect();
        };
    }, [selectedIdSession]);

    return (
        <>
            <div className={`clean-chat ${(isCheckMessages || isLoadingMessage) ? 'clean-chat--disabled' : ''}`}>
                <BurgerIcon className="clean-chat--burger" onClick={onClickBurger}/>
                <Text size="f16" font="liteMedium">{t('chat_title')}</Text>
                <div className="clean-chat--right-block">
                    <Form.Check
                        type="switch"
                        className="clean-chat--switcher"
                        checked={isGlobalChat}
                        onChange={(e)=>updateGlobalChat(e?.target?.checked)}
                        disabled={!checkVideoPage}
                        label={t('this_global')}
                    />
                    <Tooltip
                        placement="bottom"
                        title={t('chat_clean_btn')}
                    >
                        <EditBoxIcon onClick={clearChat} className="ai-chats__header--edit-icon"/>
                    </Tooltip>
                </div>
            </div>
            <div className="message-block"
                 style={{height: `calc(100vh - ${containerHeight + 133}px`}}
            >
                <div
                    className="message-block__wrap   custom-scroll"
                    ref={listRef}
                >
                    <div className="message-block__list">
                        {messages.map((item, index) => (
                            <div className="message">
                                {item?.isBot ?
                                    <div className="message__avatar-bot">
                                        <OctopusIcon className='message__avatar-bot--icon'/>
                                    </div>
                                    :
                                    <img src={generateMediaUrl(userAvatar)} alt=""/>
                                }
                                <div className="message__block">
                                    <div className="message__block_title">
                                        <div className="message__block_title--name">{item.author_name}</div>
                                        <div className="message__block_title--date">
                                            {item.date ? <span>{getTimeDifference(item.date, i18n)}</span> : null}
                                            {item?.used_db_query ?
                                                <span>&#32;&#32;&#8226;&#32;&#32;used data sourses</span> : null}
                                            {item?.tokens_used ?
                                                <span>&#32;&#32;&#8226;&#32;&#32;{getTokensText(item.tokens_used)}</span> : null}
                                        </div>
                                    </div>
                                    {(!isLoadingMessage && item?.isBot && index === messages.length - (!responseData?.session?.is_active ? 2 : 1)) &&
                                        <div style={{marginBottom: 15}}>
                                            {
                                                (logicalMessages || []).map((msg) => {
                                                    return (
                                                        <>
                                                            {msg?.text ?
                                                                <details className="message__block__details">
                                                                    <summary>
                                                                        <MarkDownText
                                                                            style={{height: `calc(${listRef.current.offsetWidth} - 200)`}}
                                                                            className="message__block__details--header"
                                                                            text={msg?.header}
                                                                        />
                                                                    </summary>
                                                                    <MarkDownText
                                                                        className="message__block__details--body"
                                                                        text={msg?.text}
                                                                    />
                                                                </details>
                                                                :
                                                                <MarkDownText
                                                                    style={{height: `calc(${listRef.current.offsetWidth} - 200)`}}
                                                                    className="message__block__details--header"
                                                                    text={msg?.header}
                                                                />
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    <MarkDownText
                                        className="message__block--text"
                                        text={item.text}
                                    />
                                    <SourcesBlock
                                        messages={messages}
                                        listRef={listRef}
                                        videoInfo={videoInfo}
                                        videoId={videoId}
                                        item={item}
                                        copyMessage={copyMessage}
                                    />
                                </div>
                            </div>
                        ))}
                        {isLoadingMessage ?
                            <div className="message">
                                <div className="message__avatar-bot">
                                    <OctopusIcon className='message__avatar-bot--icon'/>
                                </div>
                                <div className="message__block">
                                    <div className="message__block_title">
                                        <div className="message__block_title--name">Secretopus</div>
                                        <div
                                            className="message__block_title--date">{i18n.language === 'ru' ? '0 мин. назад' : '0 m. ago'}</div>
                                    </div>

                                    {(logicalMessages || []).map((msg) => {
                                        return (
                                            <details className="message__block__details">
                                                <summary>
                                                    <MarkDownText
                                                        style={{height: `calc(${listRef.current.offsetWidth} - 200)`}}
                                                        className="message__block__details--header"
                                                        text={msg?.header}
                                                    />
                                                </summary>
                                                <MarkDownText
                                                    className="message__block__details--body"
                                                    text={msg?.text}
                                                />
                                            </details>
                                        )
                                    })}
                                    <div className="message__block--text-loader">
                                        {t('chat_querying_db')}
                                        <Loader size={16}/>
                                    </div>
                                </div>
                            </div>
                            : null}
                        {error ?
                            <div className="message">
                                <div className="message__avatar-bot">
                                    <OctopusIcon className='message__avatar-bot--icon'/>
                                </div>
                                <div className="message__block">
                                    <div className="message__block_title">
                                        <div className="message__block_title--name">Secretopus</div>
                                        <div
                                            className="message__block_title--date">{getTimeDifference(dayjs(), i18n)}</div>
                                    </div>
                                    <div className="message__block--text-error">
                                        {t('chat_system_error')}
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
            <div className="chat__footer">
                <Tooltip
                    placement="left"
                    title={t('chat_tooltip_badge_search')}
                >
                    <div className='chat__tabs'>
                        <div
                            className={`chat__tabs-item ${activeTab === TABS.MESSAGE ? 'chat__tabs--active' : ''}`}
                            onClick={() => setActiveTab(TABS.MESSAGE)}
                        >
                            <Octopus2Icon className='chat__tabs_icon-octopus'/>
                        </div>
                        <div
                            className={`chat__tabs-item ${activeTab === TABS.SEARCH ? 'chat__tabs--active' : ''}`}
                            onClick={() => setActiveTab(TABS.SEARCH)}
                        >
                            <SearchIcon className='chat__tabs_icon-search'/>
                        </div>
                    </div>
                </Tooltip>
                <div
                    className="chat-input"
                    tabIndex={0}
                >
					<textarea
                        ref={textAreaRef}
                        value={text}
                        onChange={changeText}
                        onKeyDown={handleKeyDown}
                        className="chat-input__input custom-scroll"
                        placeholder={t('chat_message_input')}
                        id={"autoTextarea"}
                    />
                    {isLoadingMessage ? <Loader size={20}/> :
                        <ArrowTopCircleIcon
                            onClick={() => sendMessage(text)}
                            className={`chat-input__right-icon${text ? '--active' : ''}`}/>
                    }
                </div>
            </div>
            <Toast
                className='upload_video_answer'
                onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
                show={toastInfo.isShow}
                delay={3000}
                autohide
            >
                <div className="toasblock">
                    {toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
                    <div className="toasblock__wrap">
						<span
                            className="toasblock__wrap--title">{t('toast_success_title')}</span>
                        <span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
                    </div>
                </div>
            </Toast>
        </>
    );
};

export default MessageBlock;