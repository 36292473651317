import React, {useEffect, useRef, useState} from 'react';
import './ChatBlock.scss';
import {ArrowLeftIcon, OctopusIcon} from "../../image";
import {Popover, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import ChatWrapper from "./components/ChatWrapper/ChatWrapper";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {history} from "../../utils/history";
import {ROUTERS_MAP} from "../../utils/routes";
import UserInfoModal from "../../pages/Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";
import {isCheckAuth} from "../../utils/helperFunctions";
import {Text} from "../../modules";
import AiChats from "./components/AiChats/AiChats";
import {
	GetChatSessionsService, UpdateChatSessionService,
	UpdateGlobalChatService,
	UpdateOpenChat,
	UpdateSelectedIdSessionService
} from "../../redux/actions/chatActions";
import {io} from "socket.io-client";
import {HOST_NAME} from "../../redux/api";


const ChatBlock = (props) => {
	const {t} = useTranslation();
	const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
	const [open, setOpen] = useState(false);
	const location = useLocation();
	const [showPersonsModal, setShowPersonsModal] = useState(false)
	const [currentUser, setCurrentUser] = useState(null);
	const userProfile = useSelector(state => state.profile.userProfile);
	const checkVideoPage = (location.pathname || '').includes('/dashboard/videos/video');
	const [distanceToRight, setDistanceToRight] = useState(40);
	const dispatch = useDispatch();
	const isOpenChat = useSelector(state => state.chat.isOpenChat);
	const videoActiveTab = useSelector(state => state.videos?.videoActiveTab);
	const videoById = useSelector(state => state.videos?.videoById);
	const isCheck = checkVideoPage && videoActiveTab === 1;
	const token = localStorage.getItem('jwtToken');
	const isAuth = useSelector(state => Boolean(state.auth?.token));
	const isGlobalChat = useSelector(state => state.chat.isGlobalChat);
	const selectedIdSession = useSelector(state => state.chat.selectedIdSession);
	const selectedIdSessionRef = useRef(null);
	const isGlobalRef = useRef(isGlobalChat);
	const isOpenChatRef = useRef(isOpenChat);
	const sessionsInfo = useSelector(state => state.chat.sessionsInfo);
	const newSession = (sessionsInfo || []).filter((item) => !item?.is_read).length;
	const videoId = (!isGlobalChat && checkVideoPage) ? (location.pathname || '').split('/')?.at(-1) : null;

	useEffect(() => {
		selectedIdSessionRef.current = selectedIdSession; // Обновляем ref при каждом изменении состояния
	}, [selectedIdSession]);

	useEffect(() => {
		isGlobalRef.current = isGlobalChat; // Обновляем ref при каждом изменении состояния
	}, [isGlobalChat]);

	useEffect(() => {
		isOpenChatRef.current = isOpenChat; // Обновляем ref при каждом изменении состояния
	}, [isOpenChat]);

	function openUserModal(data) {
		if(userProfile?.attached_person?.id === data.id){
			history.push(ROUTERS_MAP.PROFILE);
		}else{
			setShowPersonsModal(true)
			setCurrentUser(data)
		}
	}
	function closeModal() {
		setShowPersonsModal(false)
	}

	const hidePopover = () => {
		setOpen(false);
	};

	const handleOpenChange = (newOpen) => {
		dispatch(UpdateOpenChat())
		setOpen(newOpen);
	};

	useEffect(()=>{
		console.log('vDVA')
		const manageVideo = document.querySelector('.chat-anchor');
		// Получаем его размеры и позицию относительно окна
		if (manageVideo){
			const rect = manageVideo.getBoundingClientRect();
			// Расстояние от правой стороны окна до правой стороны блока
			const distanceToRight = window.innerWidth - rect.right;
			setDistanceToRight(distanceToRight)
		}
	},[window.location.href])

	const getSessions = async () => {
		try{
			dispatch(UpdateGlobalChatService(!checkVideoPage));
			const data = await dispatch(GetChatSessionsService({
				video_id: !checkVideoPage ? undefined : Number(videoId)
			}));
			dispatch(UpdateSelectedIdSessionService(data?.[0]?.id));
			if(data?.[0] && !data?.[0]?.is_read) UpdateChatSessionService(data?.[0]?.id,{is_read: true});

		}catch (e) {
			console.error(e)
		}
	}

	useEffect(()=>{
		setTimeout(()=>{
			if(isAuth){
				getSessions();
				setIsVisibleTooltip(true)
				setTimeout(() =>{
					setIsVisibleTooltip(false);
					localStorage.removeItem('tooltipChat')
				},5000)
			}
		},500)
	},[isOpenChat, location])

	const blockRef = useRef(null);

	const handleClick = (event) => {
		if (blockRef.current && !blockRef.current.contains(event.target)) {
			// Клик вне блока
			if (event.target.classList.contains('.control_btn') || event.target.classList.contains('.play-btn') || event.target.classList.contains('.chat')) {
			} else {
				setOpen(false)
			}
		} else {
			// Клик внутри блока
			setOpen(true)
		}
	};

	React.useEffect(() => {
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	useEffect(()=>{
		if(isOpenChat){
			dispatch(UpdateOpenChat())
		}
	},[location])

	const updateSessions = async (data) => {
		const id = (location.pathname || '').split('/')?.at(-1);
		try{
			if(!isOpenChatRef?.current){
				dispatch(GetChatSessionsService({
					video_id:  undefined
				}));
			}else{
				if(data?.update_type === 'delete'){
					const response = await dispatch(GetChatSessionsService({
						video_id: isGlobalRef?.current ? undefined : id
					}));
					if(data?.object_id === selectedIdSessionRef?.current){
						dispatch(UpdateSelectedIdSessionService(response?.[0]?.id));
						if(!response?.[0]?.is_read) UpdateChatSessionService(response?.[0]?.id,{is_read: true});
					}
				}
				if(data?.update_type === 'create'){
					dispatch(GetChatSessionsService({
						video_id: isGlobalRef?.current ? undefined : id,
					}));
				}
				if(data?.update_type === 'update'){
					dispatch(GetChatSessionsService({
						video_id: isGlobalRef?.current ? undefined : id
					}));
				}
			}

		}catch (e) {
			console.error(e)
		}
	}


	useEffect(()=>{
		const socketFavorites = io(`${HOST_NAME}/document_updates`)

		if(isAuth){

			socketFavorites.on('connect', function() {
				console.log('Подключено к серверу user');
				socketFavorites.emit('join_room', {
					token: token,
					room: `user_${userProfile?.id}`
				}, function(response) {
					if (response && response.error) {
						console.error('Ошибка при присоединении к комнате:', response.error);
					} else {
						console.log(`Присоединились к комнате user_${userProfile?.attached_person?.id}`);
					}
				});
			});

// Обрабатываем ошибку подключения
			socketFavorites.on('connect_error', function(error) {
				console.error('Не удалось подключиться:', error);
			});

			// Обрабатываем отключение от сервера
			socketFavorites.on('disconnect', function(reason) {
				console.log('Отключено от сервера:', reason);
			});

			// Слушаем события 'update' и выводим их в консоль
			socketFavorites.on('update', async function(data) {
				if(data?.object_type === 'chat_session'){
					updateSessions(data)
				}
				// if(!isEditProtocolIdRef.current){
				// 	setTimeout(async ()=>{
				// 		await props.GetProtocol(params.videoId);
				// 	},500)
				// }else if(data?.update_type === 'delete' && data?.object_id === isEditProtocolIdRef.current){
				// 	await props.GetProtocol(params.videoId);
				// }
				console.log('Получено обновление:', data);
			});
		}
		return () => {
			socketFavorites.disconnect();
		}
	}, [isAuth, userProfile]);

	return (
		<div
			className="chat-block"
			onWheel={(e) => e.stopPropagation()}
			ref={blockRef}
		>
			<Popover
				arrow={false}
				trigger='click'
				placement="topRight"
				content={<AiChats onClick={(e) => e.stopPropagation()} hidePopover={hidePopover} openUserModal={openUserModal} isOpenPopover={open}/>}
				overlayClassName="chat-block__popover"
				destroyTooltipOnHide={true}
				// onOpenChange={handleOpenChange}
				autoAdjustOverflow
				open={isCheck ? false : isOpenChat}
			>
				<Tooltip
					open={isOpenChat ? false : isVisibleTooltip}
					placement="topRight"
					overlayClassName="chat-block__tooltip"
					title={
						<div className={'chat-block__tooltip--wrap'}>
							<span className="chat-block__tooltip--title">{t('chat_main_tooltip_title')}</span>
							<span className="chat-block__tooltip--text">{t('chat_main_tooltip_text')}</span>
						</div>
					}
				>
					<div
						className="chat-badge"
						style={{
							right: distanceToRight
						}}
						onMouseEnter={() => setIsVisibleTooltip(true)}
						onMouseLeave={() => setIsVisibleTooltip(false)}
						onClick={(e) => {
							e.stopPropagation();
							setIsVisibleTooltip(false);
							dispatch(UpdateOpenChat())
						}}
					>
						<div className="chat-badge__container">
							<Text size="f14" font="medium" color="white">{t('ask_copilot')}</Text>
							<ArrowLeftIcon
								style={{transform: `rotate(${isOpenChat ? '90deg' : '-90deg'})`}}
							/>
							{newSession ?
								<div className="chat-badge__container--count">
									<Text size="f12" font="medium" color="white">{newSession}</Text>
								</div>
								: null}
						</div>
					</div>
				</Tooltip>
			</Popover>
			{showPersonsModal && <UserInfoModal show={showPersonsModal} closeModal={closeModal} user={currentUser}/>}
		</div>
	);
};

export default ChatBlock;