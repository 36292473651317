import React from "react";

export const SERVICE_TYPES ={
	GOOGLE: 'google',
	OUTLOOK: 'outlook',
	TELEGRAM: 'telegram',
	EXTENSION: 'extension',
	WORKSPACE: 'workspace',
	EMAIL: 'email',
	OUTLOOK_EMAIL: 'outlookEmail'
}

export const SuccessModalContext = React.createContext();
