import {_urlEmail, _urlUserConfluence, request} from "../api";
import {USER_CALENDAR_CONSTS} from "../constants";

export const GetAuthorizationUrlConfluenceService = (data) => {
    let requestData = {
        url: `${_urlUserConfluence}/confluence_auth_process_stepone`,
        method: "POST",
        data
    };
    return request(requestData)
        .then(async (res) => {
            return res?.data
        })
};

export const GetAuthorizationStateConfluenceService = (state, code) => {
    let requestData = {
        url: `${_urlUserConfluence}/confluence_auth_process_steptwo?state=${state}&code=${code}`,
        method: "GET",
    };
    return request(requestData)
        .then(async (res) => {
            return res?.data
        })
};

export const GetConfluenceSpacesService = (state, code) => {
    let requestData = {
        url: `${_urlUserConfluence}/spaces`,
        method: "GET",
    };
    return request(requestData)
        .then(async (res) => {
            return res?.data
        })
};

export const LogoutConfluenceService = () => {
    let requestData = {
        url: `${_urlUserConfluence}/token_management`,
        method: "DELETE",
    };
    return request(requestData)
        .then(async (res) => {
            return res?.data
        })
};

export const UpdateSpacesConfluenceService = (data) => {
    let requestData = {
        url: `${_urlUserConfluence}/spaces`,
        method: "POST",
        data
    };
    return request(requestData)
        .then(async (res) => {
            return res?.data
        })
};



export const GetConfluenceInfoService = () => {
    let requestData = {
        url: `${_urlUserConfluence}/token_management`,
        method: "GET",
    };
    return request(requestData)
        .then(async (res) => {
            return res?.data
        })
};

export const GetGoogleEmailInfoService = () => {
    let requestData = {
        url: `${_urlEmail}/google_auth_data`,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: USER_CALENDAR_CONSTS.GET_GOOGLE_EMAIL_USER_INFO,
                    payload: res?.data
                })
            })
    };
};


export const GetGoogleEmailsListService = () => {
    let requestData = {
        url: `${_urlEmail}/list`,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: USER_CALENDAR_CONSTS.GET_GOOGLE_EMAILS_LIST_INFO,
                    payload: res?.data
                })
            })
    };
};


export const UpdateGoogleEmailInfoService = (data) => {
    let requestData = {
        url: `${_urlEmail}/google_auth_data`,
        method: "POST",
        data
    };
    return request(requestData)
};

export const DisconnectGoogleEmailInfoService = (data) => {
    let requestData = {
        url: `${_urlEmail}/list`,
        method: "DELETE",
        data
    };
    return request(requestData)
        .then(async (res) => {
            return res?.data
        })
};