// Import packages
import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

// Import assets
import './section.scss';
import {DeletingIcon, EditIcon, EllipsisIcon} from "../../../../../image";

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {GetVideos, DeleteChannel, GetChannelById} from "../../../../../redux/actions";
import DropdownMenu from "../../../../../shared/features/dropdownMenu";
import ChannelsModal from "../../../../../shared/modals/channelModal/ChannelsModal";
import {hasPermission} from "../../../../../utils/helperFunctions";
import VideoBlock from "../VideoBlock/VideoBlock";
import ChainsDropDown from "../../../../../shared/features/Chains/ChainsDropDown/ChainsDropDown";
import {generateChannelsList} from "../../../../../shared/features/Chains/helpers";
import {Popover} from "antd";
import ChannelInfoDropDown from "../../../../../modules/Channel/ChannelInfoDropDown/ChannelInfoDropDown";
import {ROUTERS_MAP} from "../../../../../utils/routes";
import {ROLE_ITEMS} from "../../../../../utils/constants";

function Section(props) {
    const {badge, list} = props;
    const {t} = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const [activeChannelId, setActiveChannelId] = useState(undefined);
    const videosArr = list?.slice(0, 8);
    const chainsList = useSelector(state => state.chains?.chainsList || []);
    const channels = useSelector(state => state.channels?.channels || []);
    const channelsList = generateChannelsList(channels);

    function closeModal() {
        setShowModal(false)
    }

    function setChannelId(id) {
        setActiveChannelId(id)
    }

    return (
        <div className={`channels__section ${!badge?.is_private ? '' : 'private'}`}>
            <div className="channels__section-header">
                <p className="channels__section-header__name">
                    <span className="name">{badge?.name}</span>
                    {!badge?.is_private &&
                            <Popover
                              arrow={false}
                              trigger='click'
                              placement="bottomLeft"
                              overlayClassName='popover_manage-chain'
                              destroyTooltipOnHide={true}
                              content={
                                  <ChannelInfoDropDown
                                    channelId={activeChannelId}
                                  />
                              }
                            >
                                <span
                                  className="count"
                                  onClick={() => {
                                      setChannelId(badge?.id)
                                  }}
                                >
                                {badge?.users_count || 0} {t('participants')}
                            </span>
                            </Popover>
                    }
                    <Popover
                      arrow={false}
                      trigger='click'
                      placement="rightTop"
                      overlayClassName='chains-menu'
                      destroyTooltipOnHide={true}
                      content={
                          <ChainsDropDown
                              chains={badge?.chains || []}
                              channel={badge}
                              isAll={true}
                              channelStyle={channelsList?.[badge?.id]}
                            />
                      }
                      >
                       <span className="chains-menu--count">
                                &nbsp;&bull;&nbsp;{badge?.chains?.length || 0} {t('Chains')}
                            </span>
                    </Popover>

                    {!badge?.is_private && hasPermission(ROLE_ITEMS.CHANNELS_EDIT)
                        && <DropdownMenu button={
                            <EllipsisIcon className={'icon-menu'}/>}
                                         closeAfterToggle={true}
                                         className="actions-menu"
                                         contentWidth={'176px'}
                        >
                            {hasPermission(ROLE_ITEMS.CHANNELS_EDIT) &&
                              <div className={'menu-item'} onClick={(e) => {
                                e.stopPropagation()
                                // toggleUpdate()
                                setChannelId(badge?.id)
                                setShowModal(true)
                            }}>
                                <EditIcon className={''}/> {t('button_edit')}
                            </div>}
                            {hasPermission(ROLE_ITEMS.CHANNELS_DELETE) &&
                                <div className={'menu-item delete'} onClick={() => props.DeleteChannel(badge?.id)}>
                                    <DeletingIcon className={''}/> {t('button_delete')}
                                </div>}
                        </DropdownMenu>}
                </p>
                <div
                    className="channels__section-header__view-all"
                >
                    <Link
                      to={{
                          pathname: `${ROUTERS_MAP.CHANNEL}/${badge.id}`,
                          state: {
                              routs: [
                                  {
                                      path: ROUTERS_MAP.KNOWLEDGE_DATA,
                                      name: t('menu_item_knowledge')
                                  }
                              ]
                          }
                      }}>
                        {t('see_all')}&nbsp;
                        ({badge?.videos_count})
                    </Link>
                </div>
            </div>
            <div
                className={`channels__section-videos`}
            >
                {!!list?.length ? <>
                        {
                            videosArr?.map((item) => {
                                return <VideoBlock key={item?.id}
                                                   badge={badge}
                                                   channelsList={channelsList}
                                                   chainList={chainsList}
                                                   isPreview={true}
                                                   routs={[
                                                       {
                                                           path: '/dashboard/videos/review',
                                                           name: t('menu_item_knowledge')
                                                       },
                                                       {
                                                           path: '/dashboard/videos/review',
                                                           name: t('menu_item_knowledge')
                                                       },
                                                       {
                                                           path: `${ROUTERS_MAP.CHANNEL}/${badge.id}`,
                                                           name: badge?.name
                                                       }
                                                   ]}
                                                   video={item}
                                                   isChaneType={true}
                                                   isDetach={true}
                                />
                            })
                        }
                    </>
                    : <div className={`channels__section-videos-empty`}>
                        {t('empty_list')}
                    </div>}
            </div>
            {!badge?.is_private && showModal && <ChannelsModal showModal={showModal}
                                                               channelById={badge}
                                                               onHide={closeModal}/>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'videosList',
        'videos',
        'isMobile',
    ])
};

const mapDispatchToProps = {
    GetVideos,
    DeleteChannel,
    GetChannelById
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
