import {PROFILE_CONSTS} from "../constants";

export const initialState = {
    userProfile: {},
    userRoles: {},
    userSignatures: [],
    userAvatar: undefined,
    confluenceInfo: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_CONSTS.GET_USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload,
            };
        case PROFILE_CONSTS.GET_AUTH_CHECK:
            return {
                ...state,
                userRoles: action.payload?.my_roles?.[0],
            }
        case PROFILE_CONSTS.CLEAR_PROFILE:
            return {
                ...state,
                userProfile: {},
                userAvatar: undefined,
            };
        case PROFILE_CONSTS.SET_USER_PROFILE:
            return {
                ...state,
                userAvatar: action.payload,
            };
        case PROFILE_CONSTS.SET_USER_SIGNATURES:
            return {
                ...state,
                userSignatures: action.payload,
            };
        case PROFILE_CONSTS.DELETE_USER_SIGNATURES:
            return {
                ...state,
                userSignatures: state.userSignatures.filter((item) => item.id !== action.payload.id),
            };
        case PROFILE_CONSTS.UPDATE_CONFLUINCE_INFO:
            return {
                ...state,
                confluenceInfo: action.payload,
            };
        default:
            return state;
    }
}
