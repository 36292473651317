import React from "react";
import {Mark} from "@tiptap/react";
import Paragraph from "@tiptap/extension-paragraph";

export const ProtocolEditorContext = React.createContext();

export const UserColors = [
    '#958DF1',
    '#F98181',
    '#FBBC88',
    '#FAF594',
    '#70CFF8',
    '#94FADB',
    '#B9F18D',
]

export const deleteChar = (editor, char = "/") => {
    const {commands, view} = editor;
    // // Получаем весь текст документа
    const {state} = view;

    if (!view) return; // Если view не доступен, то завершаем функцию

    const {from} = state.selection;

    // Получаем символ перед курсором
    const textBeforeCursor = state.doc.textBetween(from - 1, from);
    // Проверяем, если перед курсором символ char
    if (textBeforeCursor === char) {
        console.log("Deleting '@' at the end of editor");

        // Удаляем символ '@'
        commands.deleteRange({
            from: from - (char === "/" ? 1 :  1),
            to: from,
        });
    }
}

export function formatTime(seconds) {
    if (!seconds) {
        return '00:00'
    }
    const minutes = Math.floor(seconds / 60);  // Получаем полные минуты
    const remainingSeconds = Math.floor(seconds % 60);  // Округляем секунды в меньшую сторону

    // Добавляем ведущие нули, если нужно
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}


export const TABS_ACTIONS_DROPDOWN = {
    DEFAULT_PERSON: 'DEFAULT_PERSON',
    ASSIGNEE_PERSON: 'ASSIGNEE_PERSON'
}

export function updateArrayPosition(direction, id, array) {
    const index = array.findIndex(item => item.id === id);

    if (index === -1) {
        console.error("Элемент с таким id не найден");
        return array;
    }

    if (direction === "up" && index > 0) {
        // Меняем местами текущий элемент с предыдущим
        const prevItem = array[index - 1];
        const currentItem = array[index];

        // Обновляем значения number
        currentItem.number -= 1;
        prevItem.number += 1;

        // Меняем элементы местами
        array[index - 1] = currentItem;
        array[index] = prevItem;

    } else if (direction === "down" && index < array.length - 1) {
        // Меняем местами текущий элемент с следующим
        const nextItem = array[index + 1];
        const currentItem = array[index];

        // Обновляем значения number
        currentItem.number += 1;
        nextItem.number -= 1;

        // Меняем элементы местами
        array[index + 1] = currentItem;
        array[index] = nextItem;
    }

    return array;
}


export const SHOW_ADD_FORM_TYPES = {
    UP: 'UP',
    DOWN: 'DOWN'
}

export const CustomTag = Mark.create({
    name: 'custom_tag',
    addAttributes() {
        return {
            tag: {
                default: 'div', // По умолчанию
                parseHTML: (element) => element.tagName.toLowerCase(),
                renderHTML: (attributes) => {
                    return {tag: attributes.tag};
                },
            },
            class: {
                default: null,
                parseHTML: (element) => element.getAttribute('class'),
                renderHTML: (attributes) => {
                    return attributes.class ? {class: attributes.class} : {};
                },
            },
            videoTime: {
                default: null,
                parseHTML: (element) => element.getAttribute('videoTime'),
                renderHTML: (attributes) => {
                    return attributes.videoTime ? {videoTime: attributes.videoTime } : {};
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'summary', // Ищем тег в HTML
            },
        ];
    },
    renderHTML({HTMLAttributes}) {
        const tag = HTMLAttributes.tag || 'span'; // Используем атрибуты для кастомного тега
        return [tag, HTMLAttributes, 0];
    },
});

export const CustomParagraph = Paragraph.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            class: {
                default: null,
                parseHTML: (element) => element.getAttribute('class'),
                renderHTML: (attributes) => {
                    return attributes.class ? {class: attributes.class} : {};
                },
            },
        };
    },
    renderHTML({HTMLAttributes}) {
        const tag = HTMLAttributes.tag || 'p'; // Используем атрибуты для кастомного тега
        return [tag, HTMLAttributes, 0];
    },
});

// export function parseTipTapToMarkdown(json) {
//     function processNodeContent(content = []) {
//         return content
//             .map((item) => {
//                 if (item.type === 'text') {
//                     let text = item.text || '';
//                     // Добавляем жирный текст, если есть отметка bold
//                     if (item.marks?.some((mark) => mark.type === 'bold')) {
//                         text = `**${text}**`;
//                     }
//                     return text;
//                 } else if (item.type === 'nonEditableText') {
//                     const {text, personId, personType, personName} = item.attrs || {};
//                     if (text && personId && personType) {
//                         return `@${personName || text}{"person_id":${personId}, "type":"${personType}"}`;
//                     }
//                 }
//                 return ''; // Игнорируем неизвестные типы
//             })
//             .join('');
//     }
//
//     let markdown = '';
//
//     json.forEach((node) => {
//         if (node.type === 'heading') {
//             // Генерация заголовка
//             const level = node.attrs.level || 1;
//             const text = processNodeContent(node.content);
//             markdown += `${'#'.repeat(level)} ${text}\n`;
//         } else if (node.type === 'paragraph') {
//             // Генерация параграфа
//             const paragraphText = processNodeContent(node.content);
//             markdown += `${paragraphText}\n`;
//         } else if (node.type === 'taskList') {
//             // Обработка taskList
//             node.content.forEach((taskItem) => {
//                 const checked = taskItem.attrs.checked ? 'x' : ' ';
//                 const taskText = processNodeContent(taskItem.content[0]?.content); // Извлекаем текст из paragraph -> content
//                 markdown += `[${checked}] ${taskText}\n`;
//             });
//         }
//     });
//
//     return markdown.trim(); // Убираем лишние переносы в конце
// }
function replaceTimeWithJson(text) {
    // Регулярное выражение для времени, которое НЕ находится внутри {"video_time": "..."}
    const timeRegex = /(?<!{"video_time":\s*")\b(\d{1,2}):(\d{2})\b(?!")/g;

    // Замена найденных конструкций на объектный формат
    const result = text.replace(timeRegex, (match) => {
        return `{"video_time": "${match}"}`;
    });

    return result;
}

export function parseTipTapToMarkdown(json) {
    console.log('json', json);

    function processNodeContent(content = []) {
        return content
            .map((item) => {
                if (item.type === 'text') {
                    let text = item.text || '';
                    // Добавляем жирный текст, если есть отметка bold
                    if (item.marks?.some((mark) => mark.type === 'bold')) {
                        text = `**${text}**`;
                    }
                    // Обработка кастомных тегов
                    if (item.marks?.some((mark) => mark.type === 'custom_tag')) {
                        item.marks.forEach((mark) => {
                            if (mark.type === 'custom_tag') {
                                const { tag, class: className } = mark.attrs || {};
                                if (className === 'video-time') {
                                    text = `{"video_time":"${text}"}`;
                                } else if (className === 'customSummary') {
                                    text = ``;
                                } else if (tag) {
                                    text = `${text}`;
                                }
                            }
                        });
                    }
                    return replaceTimeWithJson(text);
                } else if (item.type === 'taskList') {
                    // Обработка taskList
                    return item.content
                        .map((taskItem) => {
                            const checked = taskItem.attrs.checked ? 'x' : ' ';
                            const taskText = processNodeContent(taskItem.content);
                            return `[${checked}] ${taskText}`;
                        })
                        .join('\n');
                } else if (item.type === 'nonEditableText') {
                    const { text, personId, personType, personName } = item.attrs || {};
                    if (text && personId && personType) {
                        return `@${personName || text}{"person_id":${personId}, "type":"${personType}"}`;
                    }
                }else if(item.type === 'paragraph' && item?.content?.length){
                    const text = processNodeContent(item.content);
                    return `${text}/br/`;
                }else if(item.type === 'paragraph'){
                    return `\n\n`;
                }
                return ''; // Игнорируем неизвестные типы
            })
            .join('');
    }

    function processListMark(node,nextNode, level = 0) {
        let listMarkdown = '';
        const isOrderedList = node.type === 'orderedList';
        const start = node.attrs?.start || 1;
        console.log('node.content.length',node.content);
        console.log('nextNode',nextNode);
        node.content.forEach((listItem, index) => {
            const itemNumber = isOrderedList ? `${start + index}. ` : '- ';
            const listItemContent = listItem.content
                .map((childNode) => {
                    console.log('childNode',childNode);
                    if (childNode.type === 'paragraph') {
                        return processNodeContent(childNode.content);
                    } else if (childNode.type === 'orderedList' || childNode.type === 'bulletList') {
                        return '\n' + processListMark(childNode, node.content?.[index + 1], level + 1); // Рекурсия для вложенного списка
                    }
                    return '';
                })
                .join('');
            console.log('listItemContent',listItemContent);

            console.log('listItem.content',listItem.content);
            console.log('level',level);
            listMarkdown += `${'  '.repeat(level)}${itemNumber}${listItemContent}${index === node.content.length - 1 && nextNode?.type === 'listItem' ? '' : `\n`}`;
            // if(index === node.content.length - 1){
            //   listMarkdown +=''
            // }
        });

        return listMarkdown;
    }

    let markdown = '';

    json.forEach((node, index) => {
        if (node.type === 'heading') {
            // Генерация заголовка
            const level = node.attrs.level || 1;
            const text = processNodeContent(node.content);
            markdown += `${'#'.repeat(level)} ${text}\n`;
        } else if (node.type === 'paragraph') {
            // Генерация параграфа
            const paragraphText = processNodeContent(node.content);
            markdown += `${paragraphText}\n`;
        } else if (node.type === 'taskList') {
            // Обработка taskList
            node.content.forEach((taskItem) => {
                const checked = taskItem.attrs.checked ? 'x' : ' ';
                const taskText = processNodeContent(taskItem.content); // Извлекаем текст из paragraph -> content
                markdown += `[${checked}] ${taskText}\n`;
            });
        }else if(node.type === 'listItem'){
            return parseTipTapToMarkdown(node.content)
        }else if(node.type === "horizontalRule"){
            markdown += `-----\n`;
        }
        else if (node.type === 'orderedList' || node.type === 'bulletList') {
            // Обработка списков
            markdown += processListMark(node,json?.[index + 1]);
        }
    });

    return markdown.trim(); // Убираем лишние переносы в конце
}

function processBoldText(line) {
    const parts = [];
    const boldRegex = /\*\*(.*?)\*\*/g;
    const mentionRegex = /@([\p{L}\s\d\-]+)\s*\{\s*"person_id":\s*(\d+|null),\s*"type":\s*"([\w]+)"\s*\}/gu;
    let lastIndex = 0;

    let match;
    while ((match = boldRegex.exec(line)) !== null) {
        // Обработка текста перед жирным фрагментом
        if (match.index > lastIndex) {
            const segment = line.substring(lastIndex, match.index);
            parts.push(...processMentions(segment, mentionRegex));
        }

        // Добавить жирный текст
        parts.push({
            type: 'text',
            text: match[1],
            marks: [{type: 'bold'}],
        });
        lastIndex = boldRegex.lastIndex;
    }

    // Обработка оставшегося текста
    if (lastIndex < line.length) {
        const segment = line.substring(lastIndex);
        console.log('SEGMENT',segment)
        parts.push(...processMentions(segment, mentionRegex));
    }

    return parts;
}

function processLinks(segment) {
    const parts = [];
    let lastIndex = 0;
    let match;
    // const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g; // Регулярное выражение для поиска ссылок в формате [текст](ссылка)
    const linkRegex = /https?:\/\/\S+/g;

    while ((match = linkRegex.exec(segment)) !== null) {

        // Добавить текст перед упоминанием
        if (match.index > lastIndex) {
            parts.push({
                type: 'text',
                text: segment.substring(lastIndex, match.index),
            });
        }

        parts.push(
            {
                type: 'text',
                text: `${match[0]}`,
                marks: [
                    {
                        type: 'custom_tag',
                        attrs: {
                            tag: 'a',
                            class: 'custom-link',
                            dataLink: match[0]
                        },
                    }]
            },
            {
                type: 'text',
                text: ` `
            }
        );

        lastIndex = linkRegex.lastIndex;
    }


    // Добавить оставшийся текст
    if (lastIndex < segment.length) {
        parts.push({
            type: 'text',
            text: segment.substring(lastIndex),
        });
    }

    return parts;

}

function processTime(segment) {
    const parts = [];
    let lastIndex = 0;
    let match;
    const timeRegex =  /\{"video_time":\s*"(.*?)"\}/g;

    while ((match = timeRegex.exec(segment)) !== null) {
        // Добавить текст перед упоминанием
        if (match.index > lastIndex) {
            const line = segment.substring(lastIndex, match.index);
            parts.push(...processLinks(line));
        }
        parts.push(
            {

                type: 'text',
                text: `${match[1]}`,
                marks: [
                    {
                        type: 'custom_tag',
                        attrs: {
                            tag: 'span',
                            class: 'video-time',
                            videoTime: match[1]
                        },
                    }]
            });

        lastIndex = timeRegex.lastIndex;
    }

    if (lastIndex < segment.length) {
        const line = segment.substring(lastIndex);
        parts.push(...processLinks(line));
    }

    // // Добавить оставшийся текст
    // if (lastIndex < segment.length) {
    //   parts.push({
    //     type: 'text',
    //     text: segment.substring(lastIndex),
    //   });
    // }

    return parts;
}

function processMentions(segment, mentionRegex) {
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = mentionRegex.exec(segment)) !== null) {
        // Добавить текст перед упоминанием
        if (match.index > lastIndex) {
            const line = segment.substring(lastIndex, match.index);
            parts.push(...processTime(line));
        }

        // Добавить упоминание
        parts.push({
            type: 'nonEditableText',
            attrs: {
                text: `@${match[1]}${match[3] === 'assignee' ? '(assignee)' : ''}`,
                personName: match[1],
                personId: match[2],
                personType: match[3],
            },
        });

        lastIndex = mentionRegex.lastIndex;
    }

    // Добавить оставшийся текст
    if (lastIndex < segment.length) {
        const line = segment.substring(lastIndex);
        parts.push(...processTime(line));
    }

    return parts;
}

function processDetailsContent(detailsText) {
    const parts = [];
    const checkboxRegex = /^\[\s*([ x]?)\s*\]\s+(.*)$/gm;  // Регулярное выражение для чекбоксов

    let lastIndex = 0;
    let match;

    // Обрабатываем чекбоксы
    while ((match = checkboxRegex.exec(detailsText)) !== null) {
        if (match.index > lastIndex) {
            const segment = detailsText.substring(lastIndex, match.index);
            parts.push({ type: 'text', text: segment });
        }

        // Преобразуем строку с чекбоксом в taskList
        const checked = match[1] === 'x';  // Если 'x', значит чекбокс заполнен
        const taskContent = match[2].trim();

        parts.push({
            type: 'taskList',
            content: [
                {
                    type: 'taskItem',
                    attrs: { checked: checked },
                    content: [
                        {
                            type: 'paragraph',
                            content: [{ type: 'text', text: taskContent }],
                        },
                    ],
                },
            ],
        });

        lastIndex = checkboxRegex.lastIndex;
    }

    // Добавляем остаток текста после последнего чекбокса
    if (lastIndex < detailsText.length) {
        const remainingText = detailsText.substring(lastIndex);
        parts.push({ type: 'text', text: remainingText });
    }

    return parts;
}

export function convertToTiptapJSON(inputText, isEdit) {
    const content = [];
    let isDetails = false;
    const lines = inputText.split('\n');
    console.log('lines',lines)
    function processList(lines, startIndex, listType, indentLevel = 0) {
        const listContent = [];
        let index = startIndex;

        while (index < lines.length) {
            const line = lines[index];
            const bulletRegex = /^(\s*)([-*])\s+(.*)$/;
            const orderedRegex = /^(\s*)(\d+)\.\s+(.*)$/;
            const match = listType === 'bulletList' ? line.match(bulletRegex) : line.match(orderedRegex);

            if (match) {
                const currentIndent = match[1].length;
                const text = match[3];

                if (currentIndent > indentLevel) {
                    const nestedResult = processList(lines, index, listType, currentIndent);
                    index = nestedResult.endIndex;

                    listContent.push({
                        type: 'listItem',
                        content: [
                            { type: 'paragraph', attrs: { class: null }, content: processBoldText(text.trim()) },
                            { type: listType, attrs: { start: 1 }, content: nestedResult.listContent },
                        ],
                    });
                } else if (currentIndent === indentLevel) {
                    listContent.push({
                        type: 'listItem',
                        content: [
                            { type: 'paragraph', attrs: { class: null }, content: processBoldText(text.trim()) },
                        ],
                    });
                } else {
                    break;
                }
            } else {
                const otherListType = listType === 'bulletList' ? 'orderedList' : 'bulletList';
                const otherMatch = otherListType === 'bulletList' ? line.match(bulletRegex) : line.match(orderedRegex);

                if (otherMatch && otherMatch[1].length > indentLevel) {
                    const nestedResult = processList(lines, index, otherListType, otherMatch[1].length);
                    index = nestedResult.endIndex;

                    const lastItem = listContent[listContent.length - 1];
                    if (lastItem && lastItem.type === 'listItem') {
                        lastItem.content.push({
                            type: otherListType,
                            attrs: { start: 1 },
                            content: nestedResult.listContent,
                        });
                    }
                } else {
                    break;
                }
            }
            index++;
        }

        return { listContent, endIndex: index - 1 };
    }

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (isDetails) {
            if (line.includes('</details>')) {
                isDetails = false;
            }
        } else {
            if (line.startsWith('######')) {
                console.log('######', processBoldText(line.replace('###### ', '').trim()))
                content.push({
                    type: 'heading',
                    attrs: { level: 6 },
                    content: processBoldText(line.replace('###### ', '').trim())
                });
            } else if (line.startsWith('#####')) {
                content.push({
                    type: 'heading',
                    attrs: { level: 5 },
                    content: processBoldText(line.replace('##### ', '').trim())
                });
            } else if (line.startsWith('####')) {
                content.push({
                    type: 'heading',
                    attrs: { level: 4 },
                    content: processBoldText(line.replace('#### ', '').trim())
                });
            } else if (line.startsWith('###')) {
                content.push({
                    type: 'heading',
                    attrs: { level: 3 },
                    content: processBoldText(line.replace('### ', '').trim())
                });
            } else if (line.startsWith('##')) {
                content.push({
                    type: 'heading',
                    attrs: { level: 2 },
                    content: processBoldText(line.replace('## ', '').trim())
                });
            } else if (line.startsWith('#')) {
                content.push({
                    type: 'heading',
                    attrs: { level: 1 },
                    content: processBoldText(line.replace('# ', '').trim())
                });
            } else if (line.startsWith('-----')) {
                content.push({
                    type: "horizontalRule",
                });
            } else if (!line) {
                content.push({
                    type: "paragraph",
                });
            }else if (line.trim().startsWith('- ') || line.trim().startsWith('* ')) {
                const result = processList(lines, i, 'bulletList');
                content.push({
                    type: 'bulletList',
                    attrs: { class: null },
                    content: result.listContent,
                });
                i = result.endIndex;
            } else if (/^\d+\. /.test(line.trim())) {
                const result = processList(lines, i, 'orderedList');
                content.push({
                    type: 'orderedList',
                    attrs: { start: 1, class: null },
                    content: result.listContent,
                });
                i = result.endIndex;
            }else if (line.includes('<details>')) {
                const summaryMatch = line.match(/<summary>(.*?)<\/summary>/);
                const detailsMatch = inputText.match(/<details>([\s\S]*?)<\/details>/);
                isDetails = true;
                if (summaryMatch && detailsMatch) {
                    const summaryText = summaryMatch[1].trim();
                    const detailsText = detailsMatch[1].trim();
                    if (isEdit) {
                        content.push({
                            type: 'paragraph',
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { class: 'test' },
                                    content: [
                                        {
                                            type: 'text',
                                            text: `<test>323${summaryText}</test>`
                                        },
                                    ],
                                },
                                {
                                    type: 'paragraph',
                                    content: [
                                        {
                                            type: 'text',
                                            text: `<summary>${detailsText}</summary>`,
                                        },
                                    ],
                                },
                            ],
                        });
                    } else {
                        content.push({
                            type: 'paragraph',
                            attrs: {
                                class: 'customParagraph',
                            },
                            content: [
                                {
                                    type: 'text',
                                    text: summaryText,
                                    marks: [
                                        {
                                            type: 'custom_tag',
                                            attrs: {
                                                tag: 'p',
                                                class: 'customSummary',
                                            },
                                        },
                                    ],
                                },
                                ...processBoldText(`<details>${detailsText}</details>`).map((part) => ({
                                    ...part,
                                    marks: (part.marks || []).concat({
                                        type: 'custom_tag',
                                        attrs: {
                                            tag: 'p',
                                            class: 'customDetails',
                                        },
                                    }),
                                })),
                            ],
                        });
                    }
                }
            } else if (line) {
                const checkboxRegex = /^\[\s*([ x]?)\s*\]\s+(.*)$/;
                const match = line.match(checkboxRegex);
                if (match) {
                    const checked = match[1] === 'x';
                    const taskContent = match[2].trim();
                    // const arr = taskContent.split('\n').map((item)=>{
                    //   return{
                    //     type: 'paragraph',
                    //       content: processBoldText(taskContent.trim()),
                    //   }
                    // })
                    console.log('match[2]',match[2])
                    console.log('match[2].trim()',match[2].trim())
                    console.log('taskContent',taskContent)
                    console.log('taskContent.split',taskContent.split('/br/'))
                    const taskItem = {
                        type: 'taskList',
                        content: [
                            {
                                type: 'taskItem',
                                attrs: {
                                    checked: checked,
                                },
                                content: taskContent.split('/br/').filter((item)=>Boolean(item)).map((item)=>{
                                    return{
                                        type: 'paragraph',
                                        content: processBoldText(item.trim()),
                                    }
                                }),
                            },
                        ],
                    };
                    content.push(taskItem);
                }else {
                    content.push({
                        type: 'paragraph',
                        content: processBoldText(line.trim()),
                    });
                }
            }
        }
    }
    return { type: 'doc', content };
}