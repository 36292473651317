import React, {useContext, useState} from 'react';
import {Modal, Tooltip} from "antd";
import './SettingsAutoChainsModal.scss';
import {CloseStrokeIcon, InfoIcon, LoopIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import SettingsAutoChainsShareLink from "./components/SettingsAutoChainsShareLink/SettingsAutoChainsShareLink";
import {Button} from "../../../Button";
import SettingsAutoChainsChannels from "./components/SettingsAutoChainsChannels/SettingsAutoChainsChannels";
import {AutoCainsSettingsContext, getChainsFromChannel, hasSettingsChanged} from "./helpers";
import {CheckBox} from "../../../CheckBox";
import {useDispatch, useSelector} from "react-redux";
import {UpdateSettingsEvent} from "../../../../redux/actions";
import {ToastContext} from "../../../Toast/ToastContext";
import {addChain, getChains} from "../../../../redux/actions/chainsActions";
import {Loader} from "../../../../shared/features/Loader";

const SettingsAutoChainsModal = (props) => {
	const {isOpen, onClose, data, event, updateEventsList} = props;
	const {t} = useTranslation();
	const [settings, setSettings] = React.useState({
		share_link_is_public: data?.share_link_is_public || false,
		channels_ids: data?.channels_ids || [],
		chains_ids: data?.chains_ids || [],
		auto_chain_name: data?.auto_chain_name || undefined,
		need_auto_chain: data?.need_auto_chain || false,
		auto_chain_channel_id: data?.auto_chain_channel_id || undefined,
		share_link_expiration_time: data?.share_link_expiration_time || undefined,
		is_global: data?.is_global || null,
		is_auto: !!data?.is_auto,
	});

	const [disabledAccept, setDisabledAccept] = React.useState(false);
	const [isCheckAccept, setIsCheckAccept] = React.useState(false);
	const isCheckAcceptBlock = String(data?.is_auto) !== 'false';
	const isCheckAutoSettings = String(data?.is_auto) === 'true';
	const [isVisibleModalChangeTypeEvent, setIsVisibleModalChangeTypeEvent] = React.useState(false);
	const [typeMeeting, setTypeMeeting] = React.useState(false);
	const {setToastInfo} = useContext(ToastContext);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const channels = useSelector(state => state.channels?.channels || []);
	const chainsAll = useSelector(state => state.chains?.chainsList || []);
	const [autoChainsArr, setAutoChainsArr] = React.useState([]);

	const changeAutoChains =  (id, value) => {
		if(value && !autoChainsArr.includes(id)){
			setAutoChainsArr([...autoChainsArr, id]);
		}
		if(!value && autoChainsArr.includes(id)){
			setAutoChainsArr(autoChainsArr.filter(item => item !== id));
		}
	}

	const onCheckAccept = (e) => {
		setIsCheckAccept(e?.target?.checked);
		if(data?.is_auto) {
			setSettings({
				auto_chain_name: data?.auto_chain_name,
				auto_chain_channel_id: data?.auto_chain_channel_id,
				need_auto_chain: true,
				share_link_is_public: false,
				channels_ids: [],
				chains_ids: [],
			})
		} else {
			setSettings({
				need_auto_chain: false,
				share_link_is_public: false,
				channels_ids: [],
				chains_ids: [],
			})
		}
	}
	const onChangeSettings = async (data) => {
		setSettings(data);
		setDisabledAccept(true);
		setIsCheckAccept(false)
	}

	const handleApplyClick = async () => {
		const userChannelIds = channels.map(channel => channel.id);
		const filteredChannelsIds = settings.channels_ids.filter(id => userChannelIds.includes(id));
		const chainsIds = chainsAll.map(chain => chain?.id);
		const filteredChainsIds = settings.chains_ids.filter(id => chainsIds.includes(id));
		const autoChainsIds = [];
		try {
			if(event?.is_recurrence) {
				if(isVisibleModalChangeTypeEvent) {
					setLoading(true);
					if(autoChainsArr.length){
						const promises = autoChainsArr.map(async (id) => {
							const response = await dispatch(addChain({
								name: `Auto ${event?.summary}`.trim(),
								channel_id: id,
								description: ""
							}));
							autoChainsIds.push(response?.id);
						});

						await Promise.all(promises);
					}

					await UpdateSettingsEvent(event?.id, {
						preset: {
							...settings,
							is_global: typeMeeting,
							channels_ids: filteredChannelsIds,
							chains_ids: [...filteredChainsIds, ...autoChainsIds],
							auto_chain_name: settings.need_auto_chain ? settings?.auto_chain_name : undefined,
							auto_chain_channel_id: settings.need_auto_chain ? settings?.auto_chain_channel_id : undefined,
						}
					});
					await dispatch(getChains())

					await  updateEventsList();
					setToastInfo({
						isShow: true,
						type: 'success',
						text: t("event_update_success_text")
					});
					setLoading(false);
					onClose();
				} else {
					setIsVisibleModalChangeTypeEvent(true)
				}
			} else {
				setLoading(true);
				if(autoChainsArr.length){
					const promises = autoChainsArr.map(async (id) => {
						const response = await dispatch(addChain({
							name: `Auto ${event?.summary}`.trim(),
							channel_id: id,
							description: ""
						}));
						autoChainsIds.push(response?.id);
					});

					await Promise.all(promises);
				}
				await UpdateSettingsEvent(event?.id, {
					preset: {
						...settings,
						is_global: false,
						channels_ids: filteredChannelsIds,
						chains_ids: [...filteredChainsIds, ...autoChainsIds],
						auto_chain_name: settings.need_auto_chain ? settings?.auto_chain_name : undefined,
						auto_chain_channel_id: settings.need_auto_chain ? settings?.auto_chain_channel_id : undefined,
					}
				});
				await dispatch(getChains())
				await updateEventsList();
				setToastInfo({
					isShow: true,
					type: 'success',
					text: t("event_update_success_text")
				});
				setLoading(false);
				onClose();
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("event_update_error_text")
			});
		}
	}

	const handleCloseModal = () => {
		if(isVisibleModalChangeTypeEvent) {
			setIsVisibleModalChangeTypeEvent(false);
		} else {
			onClose();
		}
	}

	return (
		<AutoCainsSettingsContext.Provider value={{settings, setSettings: onChangeSettings, autoChainsArr, updateAutoChains: changeAutoChains}}>
			<Modal
				centered={true}
				classNames={{
					wrapper: `auto-chains-modal--wrapper ${isVisibleModalChangeTypeEvent ? 'mini' : ''}`,
					content: 'auto-chains-modal',
				}}
				open={isOpen} onCancel={onClose}>
				<div className="auto-chains-modal__content">
					<div className='auto-chains-modal__header'>
						{isVisibleModalChangeTypeEvent ?
							<div className='auto-chains-modal__header--wrap'>
							<span className="auto-chains-modal__header--title">
							 {t('apply_changes')}
							</span>
							</div>
							:
							<div className='auto-chains-modal__header--wrap'>
								{(event?.summary || '').length > 28 ?
									<Tooltip
										title={event?.summary}
										placement="right"
									>
								<span className="auto-chains-modal__header--title">
									{`${t('sharing_settings')}: ${event?.summary}`}
								</span>
									</Tooltip>
									:
									<span className="auto-chains-modal__header--title">
							{`${t('sharing_settings')}: ${event?.summary}`}
						</span>
								}
								{event?.is_recurrence ? <LoopIcon/> : null}
							</div>
						}
						<CloseStrokeIcon onClick={handleCloseModal} className="auto-chains-modal__header--close"/>
					</div>
					<div className='auto-chains-modal__body'>
						{isVisibleModalChangeTypeEvent ?
							<div className='auto-chains-modal__body--types'>
								<CheckBox
									inline
									name="type"
									label={t('this_meeting')}
									className="auto-chains-share-link__content--toggle"
									type={'radio'}
									checked={!typeMeeting}
									onChange={(e) => setTypeMeeting(e?.target?.checked)}
								/>
								<CheckBox
									inline
									name="type"
									className="auto-chains-share-link__content--toggle"
									label={t('all_meetings')}
									type={'radio'}
									checked={typeMeeting}
									onChange={(e) => setTypeMeeting(e?.target?.checked)}
								/>
							</div>
							:
							<>
								<SettingsAutoChainsChannels settings={settings} setSettings={setSettings} event={event}/>
								<SettingsAutoChainsShareLink settings={settings} setSettings={setSettings}/>
							</>
						}
					</div>
					{isCheckAcceptBlock && !isVisibleModalChangeTypeEvent ?
						<div className='auto-chains-modal__accept'>
							<div className={`auto-chains-modal__accept--container ${isCheckAutoSettings ? 'auto' : ''}`}>
								<InfoIcon/>
								<div className='auto-chains-modal__accept--description'>
									<span>{isCheckAutoSettings ? t('accept_description_auto') : t('accept_description_absent')}</span>
									<CheckBox
										type='checkbox'
										label={t('accept')}
										disabled={disabledAccept}
										onChange={onCheckAccept}
										checked={isCheckAccept}
									/>
								</div>
							</div>
						</div>
						: null}
					<div className='auto-chains-modal__footer'>
						<Button
							text={t('button_cancel')}
							variant="secondary"
							onClick={handleCloseModal}
						/>
						<Button
							text={t('button_save')}
							variant="primary"
							rightIcon={loading ? <Loader size={16} color="white"/> : null}
							disabled={(loading || !hasSettingsChanged(settings, data)) && !autoChainsArr.length}
							onClick={handleApplyClick}
						/>
					</div>
				</div>

			</Modal>
		</AutoCainsSettingsContext.Provider>
	);
};

export default SettingsAutoChainsModal;