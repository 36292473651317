import React, {useEffect, useState} from 'react';
import './ExtensionBannerHeader.scss'
import {StarsIcon} from "../../../image";
import {Text} from "../../index";
import {Button} from "../../Button";
import {useTranslation} from "react-i18next";
import {GetExtensionLinkService} from "../../../redux/actions";
import {detectExtensionPromise} from "../../../utils/helperFunctions";

const ExtensionBannerHeader = () => {
    const {t} = useTranslation();
    const [close, setClose] = useState(false);
    const [link, setLink] = useState('');
    const [install, setInstall] = useState(false);

    const detectExtension = async () => {
        const result = await detectExtensionPromise();
        setInstall(result);
    }

    useEffect(() => {
        if (!link) {
            GetExtensionLinkService().then((res) => setLink(res?.link))
        }
        detectExtension();
    }, [])

    const onCLickUrl = () => {
        window.open(link, '_blank');
    }

    return (
        <>
            {!close && !install ?
                <div className="extension-banner">
                    <div className="extension-banner__badge">
                        <StarsIcon/>
                        <Text size="f13" color="white">NEW!</Text>
                    </div>
                    <div className="extension-banner__text">
                        <Text size="f14" fonr="medium" color="black">{t('extension_banner_text_1')}&nbsp;</Text>
                        <Text size="f14">{t('extension_banner_text_2')}</Text>
                    </div>
                    <div className="extension-banner__buttons">
                        <Button
                            onClick={() => setClose(true)}
                            size="xs"
                            variant="ghost"
                            text={t('dismiss')}
                        />
                        <Button
                            onClick={onCLickUrl}
                            size="xs"
                            text={t('add_to_chrome')}
                        />
                    </div>
                </div>
                : null}
        </>
    );
};

export default ExtensionBannerHeader;