import React, {useContext, useEffect, useState} from 'react';
import './CalendarInfoBlock.scss';
import {Text} from "../../../../modules";
import {useTranslation} from "react-i18next";
import {SERVICE_TYPES, SuccessModalContext} from "../../helpers";
import {Button} from "../../../../modules/Button";
import {useDispatch, useSelector} from "react-redux";
import InfoTooltip from "../../../Dashboard/containers/Profile/tooltip";
import {CheckBox} from "../../../../modules/CheckBox";
import {GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import {
	GetCalendarSynchronize,
	GetUserCalendars, UpdateUserCalendars
} from "../../../../redux/actions";
import {useCalendarActions} from "../../hooks";

const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];

const Info = {
	[SERVICE_TYPES.GOOGLE]: {
		description: {
			ru: 'Подключите свой календарь Google к кратким отчетам о встречах и стенограммам для автоматической записи собрания с помощью искусственного интеллекта.',
			en: 'Link your Google Calendar to your meeting summaries and transcripts for automatic meeting recording by the AI assistant.'
		},
		description_btn: {
			ru: 'Календарь Google еще не подключен. Нажмите "Подключиться", чтобы настроить его и приступить к работе.',
			en: 'Google Calendar isn`t connected yet. Click "Connect" to set it up and get started.'
		}
	},
	[SERVICE_TYPES.OUTLOOK]: {
		description: {
			ru: 'Подключите свой Microsoft Outlook к кратким отчетам о встречах и стенограммам ваших собраний для автоматической записи собрания с помощью искусственного интеллекта',
			en: 'Link your Microsoft Outlook to your meeting summaries and transcripts for automatic meeting recording by the AI assistant.'
		},
		description_btn: {
			ru: 'Microsoft Outlook еще не подключен. Нажмите "Подключиться", чтобы настроить его и приступить к работе.',
			en: 'Microsoft Outlook isn`t connected yet. Click "Connect" to set it up and get started.'
		}
	}
}
const CalendarInfoBlock = (props) => {
	const {t,i18n} = useTranslation();
	const {calendarType} = props;
	const userCalendars = useSelector(state => state.userCalendars);
	const googleToken = useSelector(state => state.userCalendars?.googleToken);
	const outlookToken = useSelector(state => state.userCalendars.outlookInfo?.token?.access_token)
	const googleUser = useSelector(state => state.userCalendars?.googleUserInfo);
	const outlookInfo = useSelector(state => state.userCalendars.outlookInfo);
	const calendars =  (userCalendars?.userCalendars || []).filter(i => !i?.calendar_id?.includes('neutronix'));
	const [checkedChannels, setCheckedChannels] = useState(null);
	const dispatch = useDispatch();
	const {handleOnSuccess} = useContext(SuccessModalContext);
	const {OutlookAuth, GoogleLogOut, OutlookLogOut} = useCalendarActions();

	const getContent = async () => {
		try{
			await dispatch(GetCalendarSynchronize());
			await dispatch(GetUserCalendars());
		}catch (e) {
			console.error(e)
		}
	}
	useEffect(()=>{
		getContent();
	},[])

	useEffect(() => {
		let channels = {}
		 calendars.forEach(item => {
			channels = {
				...channels,
				[item?.id]: {
					id: item?.id,
					enable_sync: item?.enable_sync
				}
			}
		})
		setCheckedChannels(channels)
		return () => {
			setCheckedChannels(null)
		}
	}, [userCalendars]);

	async function checkChannel(e, id) {
		let channels = {
			...checkedChannels,
			[id]: {
				id,
				enable_sync: e.target.checked
			}
		}

			await dispatch(UpdateUserCalendars(id, {
						enable_sync: channels[id]?.enable_sync
			}));
			setCheckedChannels(channels)
			await dispatch(GetCalendarSynchronize());
	}


	return (
		<div className="calendars-block">
			<Text font="medium" size="f18" className="calendars-block--title">{calendarType === SERVICE_TYPES.GOOGLE ? t('calendar_settings_user_title') : 'Microsoft Outlook'}</Text>
			{(calendarType === SERVICE_TYPES.GOOGLE  ? googleToken : outlookToken) ?
				<>
					<div className="calendars-block__info">
						{calendarType === SERVICE_TYPES.GOOGLE ?
							<div className="calendars-block__info--text">
								<Text size="f14" font="book" color="#9898A6">{t('calendar_settings_user_title')}</Text>
								<Text size="f14" font="book" color="#9898A6" as="p">{t('calendar_settings_user_name')} </Text>
								<Text size="f14" font="book" color="#212121">{googleUser?.email}</Text>
							</div>
							:
						<div className="calendars-block__info--text">
							<Text size="f14" font="book" color="#9898A6">Microsoft Outlook</Text>
							<Text size="f14" font="book" color="#9898A6" as="p">{t('calendar_settings_user_name')} </Text>
							<Text size="f14" font="book" color="#212121">{outlookInfo?.outlook_email}</Text>
						</div>
						}
						<Button
							text={t('calendar_settings_disconnect')}
							onClick={calendarType === SERVICE_TYPES.GOOGLE  ? GoogleLogOut : OutlookLogOut}
							variant="secondary"
							color="red"
							size="s"
						/>

					</div>
				</>
				:
				<>
					<div className="calendars-block__top">
						<Text font="liteMedium" size="f16" color="#9898A6">{Info[calendarType].description[i18n.language]}</Text>
					</div>
					<div className="calendars-block__bottom">
						<Text font="liteMedium" size="f16" color="#9898A6">{Info[calendarType].description_btn[i18n.language]}</Text>
						<>
						{calendarType === SERVICE_TYPES.GOOGLE  ?
							<>
								{userCalendars?.clientId ?
									<GoogleOAuthProvider clientId={userCalendars?.clientId}>
										<GoogleButton
											t={t}
											handleOnSuccess={handleOnSuccess}
										/>
									</GoogleOAuthProvider>
									: null}
							</>
							:
							<Button
								text={t('connect')}
								size="s"
								onClick={OutlookAuth}
							/>
						}
						</>
					</div>
				</>
			}
			{(calendarType === SERVICE_TYPES.GOOGLE  ? googleToken : outlookToken) ?
			<div className="calendars-block__body">
				<Text font="medium" size="f16">{t('synced_calendars')}</Text>
				<div className="calendars-block__body--list">
					{calendars
						.filter((item) => item?.calendar_service?.name === (calendarType === SERVICE_TYPES.GOOGLE  ? 'google' : 'outlook'))
						.map((data, index) => {
							return (
								<CheckBox
									type={'checkbox'}
									checked={checkedChannels?.[data.id]?.enable_sync}
									onChange={(e) => checkChannel(e, data.id)}
									label={
										<div className="label"
										     style={{
											     backgroundColor: tagColors[index >= 5 ? index % 5 : index] + '14',
											     color: tagColors[index >= 5 ? index % 5 : index],
										     }}
										>
											{data?.calendar_id?.length > 27 ? <InfoTooltip elem={data?.calendar_id}
											                                               title={data?.calendar_id}/> : data?.calendar_id}
										</div>
									}
									/>
								)
						})}
				</div>
			</div>
				: null}
		</div>
	);
};

function GoogleButton({handleOnSuccess, t}) {
	const login = useGoogleLogin({
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/calendar.readonly',
		onSuccess: async (codeResponse) => {
			handleOnSuccess(codeResponse)
		},
		onError: errorResponse => console.log(errorResponse),
	});
	return (
		<Button
			text={t('connect')}
			size="s"
			onClick={login}
		/>
	);
}

export default CalendarInfoBlock;