import React, {useEffect, useState} from 'react';
import './AiChats.scss';
import {ArrowLeftIcon, BurgerIcon, EditBoxIcon} from "../../../../image";
import {Text} from "../../../../modules";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import {useHistory, useLocation} from "react-router-dom";
import {Tooltip} from "antd";
import MessageBlock from "../MessageBlock/MessageBlock";
import ChatsList from "../ChatsList/ChatsList";
import SearchBlock from "../SearchBlock/SearchBlock";
import {useSelector} from "react-redux";
import {getHeaderHeight} from "../../../../utils/helperFunctions";

export const TABS = {
	MESSAGE: 0,
	SEARCH: 1
}
const SUB_HEADER_HEIGHT = 175;

const AiChats = (props) => {
    const {t} = useTranslation();
    const location = useLocation();
    const [isGlobal, setIsGlobal] = useState(false);
    const isCheckVideoPage = (location.pathname || '').includes('/dashboard/videos/video');
    const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
    const {hidePopover, openUserModal,isOpenPopover} = props;
    const [activeTab, setActiveTab] = useState(TABS.MESSAGE);
    const [headerHeight, setHeaderHeight] = useState(0);
    const videoActiveTab = useSelector(state => state.videos?.videoActiveTab);
    const checkVideoPage = (location.pathname || '').includes('/dashboard/videos/video');
    const isCheck = checkVideoPage && videoActiveTab === 1;

    useEffect(()=>{
        console.log('locat', location)
        setHeaderHeight(getHeaderHeight());
        if(checkVideoPage && videoActiveTab === 1){
            setHeaderHeight(205)
        }else{
            setHeaderHeight(getHeaderHeight() + 45)
        }
    },[videoActiveTab])
    console.log('hhhh',headerHeight)

    return (
        <div className="ai-chats"
             style={{
                 height: `calc(100vh - ${headerHeight}px`,
                 marginLeft: isCheck ? 20 : 0
        }}
        >
                {/*<BurgerIcon className="ai-chats__header--burger"/>*/}

            {/*<div className="ai-chats__header">*/}
            {/*    <BurgerIcon className="ai-chats__header--burger" onClick={() => setIsOpenSidePanel(!isOpenSidePanel)}/>*/}
            {/*    <Text size="f16" font="liteMedium">{t('chat_title')}</Text>*/}
            {/*    <div className="ai-chats__header--right-block">*/}
            {/*        <Form.Check*/}
            {/*            type="switch"*/}
            {/*            className="ai-chats__header--switcher"*/}
            {/*            checked={isGlobal}*/}
            {/*            onChange={(e) => setIsGlobal(e?.target.checked)}*/}
            {/*            disabled={!isCheckVideoPage}*/}
            {/*            label={t('this_global')}*/}
            {/*        />*/}
            {/*        <Tooltip*/}
            {/*            placement="bottom"*/}
            {/*            title={t('chat_clean_btn')}*/}
            {/*        >*/}
            {/*            <EditBoxIcon className="ai-chats__header--edit-icon"/>*/}
            {/*        </Tooltip>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="ai-chats__container">
                <div
                    style={{width: isOpenSidePanel ? 360 : 0}}
                    className="ai-chats__left-side"
                >
                    <ChatsList/>
                </div>
                <div
                    className="ai-chats__right-side"
                    onClick={() => {
                        if (isOpenSidePanel) setIsOpenSidePanel(false)
                    }}
                >
                    <div className="chat" >
                        {activeTab === TABS.SEARCH ?
                            <div className={`chat-wrapper__header chat__header--search`}>
                                <span className="chat__header--title">{t('input_search')}</span>
                            </div>
                            :null}
                        {activeTab === TABS.MESSAGE ?
                            <>
                                <MessageBlock
                                	activeTab={activeTab}
                                	isOpenPopover={isOpenPopover}
                                	setActiveTab={setActiveTab}
                                    onClickBurger={() => setIsOpenSidePanel(!isOpenSidePanel)}
                                    containerHeight={headerHeight}
                                />
                            </>
                            : null}
                        {activeTab === TABS.SEARCH ?
                            <SearchBlock
                                activeTab={activeTab}
                                openUserModal={openUserModal}
                                setActiveTab={setActiveTab}
                            />
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AiChats;