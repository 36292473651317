import React, {useRef, useState} from 'react';
import './ChatsList.scss'
import {getHeaderHeight} from "../../../../utils/helperFunctions";
import {EditBoxIcon, PlusIcon, PlusNewIcon, SearchIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {Tooltip} from "antd";
import {Text} from "../../../../modules";
import {useDispatch, useSelector} from "react-redux";
import {
    CreateChatSessionService,
    GetChatSessionsService, UpdateChatSessionService, UpdateGlobalChatService,
    UpdateSelectedIdSessionService
} from "../../../../redux/actions/chatActions";
import {Button} from "../../../../modules/Button";

const SUB_HEADER_HEIGHT = 120;

const ChatsList = () => {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const [searchText, setSearchText] = useState('');
    const [isGlobal, setIsGlobal] = useState(false);
    const isCheckVideoPage = (location.pathname || '').includes('/dashboard/videos/video');
    const videoInfo = useSelector(state => state.videos.videoById);
    const sessionsInfo = useSelector(state => state.chat.sessionsInfo);
    const selectedIdSession = useSelector(state => state.chat.selectedIdSession);
    const isGlobalChat = useSelector(state => state.chat.isGlobalChat);
    const dispatch = useDispatch();
    const listRef = useRef(null);

    const scrollToItem = (id) => {
        console.log('ID', id)
        const targetElement = listRef?.current?.querySelector(`#session-item-${id}`);
        console.log('targetElement',targetElement?.current)
        if (targetElement && listRef.current) {
            listRef.current.scrollTo({
                top: targetElement.offsetTop - listRef.current.offsetTop - 50,
                behavior: 'smooth',
            });
        }
    };

    const createNewSession = async () => {
        try {
            const data = await CreateChatSessionService(isGlobalChat ? {} : {video_id: videoInfo?.id});
            dispatch(UpdateSelectedIdSessionService(data?.id));
            if(!data?.is_read) UpdateChatSessionService(data?.id,{is_read: true});
            listRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } catch (e) {
            console.error(e)
        }
    }

    const updateGlobalChat = async (value) => {
        try {
            dispatch(UpdateGlobalChatService(value));
            const response = await dispatch(GetChatSessionsService({
                video_id: value ? undefined : videoInfo?.id
            }));
            dispatch(UpdateSelectedIdSessionService(response?.[0]?.id));
            if(!response?.[0]?.is_read) UpdateChatSessionService(response?.[0]?.id,{is_read: true});
        } catch (e) {
            console.error(e)
        }
    }

    const onHandleClickSession = async (value) => {
        try {
            scrollToItem(value?.id);
            dispatch(UpdateSelectedIdSessionService(value?.id));

            if(!value?.is_read){
                UpdateChatSessionService(value?.id,{is_read: true});
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="chats-list" onClick={(e) => e.stopPropagation()}>
            <div className="chats-list__header">
                <div className="chats-list__search" tabIndex={0}>
                    <div className="chats-list__search-wrap">
                        <SearchIcon/>
                        <input
                            className="chats-list__search-input"
                            value={searchText}
                            onChange={(e) => setSearchText(e?.target?.value || '')}
                            type="text"
                            placeholder={t('input_search')}
                        />
                    </div>
                </div>
                <Form.Check
                    type="switch"
                    className="chats-list__header--switcher"
                    checked={isGlobalChat}
                    onChange={(e) => updateGlobalChat(e?.target.checked)}
                    disabled={!isCheckVideoPage}
                    label={t('this_global')}
                />
                <Tooltip
                    placement="bottom"
                    title={t('chat_clean_btn')}
                >
                    <EditBoxIcon
                        className="chats-list__header--edit-icon"
                        onClick={createNewSession}
                    />
                </Tooltip>
            </div>
            <div
                style={{height: `calc(100vh - ${getHeaderHeight() + SUB_HEADER_HEIGHT}px`}}
                className="chats-list__container custom-scroll"
                ref={listRef}
            >
                {!isGlobalChat ?
                    <>
                        {sessionsInfo?.length ?
                            <Text size="f13" font="book" color="#828282" className="chats-list__container-title">
                                {t('local_chats_for_video')}
                                <Text size="f13" font="bold" color="#828282">{' '}{videoInfo?.description}</Text>
                            </Text>
                            :
                            <Text size="f13" font="book" color="#828282" className="chats-list__container-title">
                                <Text size="f13" font="book"
                                      color="#828282">{i18n.language === 'en' ? 'No chats for video' : 'Чаты для видео'}{' '}</Text>
                                <Text size="f13" font="bold" color="#828282">{' '}{videoInfo?.description}{' '}</Text>
                                {i18n.language === 'en' ? 'yet.' : 'отсутсвуют.'}
                                <Text size="f13" font="book"
                                      color="#828282">{' '}{i18n.language === 'en' ? 'Click the start chat button to begin.' : 'Нажмите кнопку \"Начать чат\", чтобы начать.'}</Text>
                            </Text>
                        }
                    </>
                    :
                    <>
                        {sessionsInfo?.length ?
                            <Text size="f13" font="book" color="#828282"
                                  className="chats-list__container-title">{t('global_chat')}</Text>
                            :
                            <Text size="f13" font="book" color="#828282"
                                  className="chats-list__container-title">{t('empty_text_chat')}</Text>
                        }
                    </>
                }
                {!sessionsInfo?.length ?
                    <Button
                        size="xs"
                        variant="ghost" leftIcon={<PlusNewIcon/>} text="Start a new chat"
                        className="chats-list__new-chat"
                        onClick={createNewSession}
                    /> : null}
                <div className="chats-list__feed">
                    {(sessionsInfo || []).filter((item) => (item?.summary || '').toLowerCase().includes(searchText.toLowerCase())).map((item) => (
                        <div
                            className={`chats-list__feed--item ${selectedIdSession === item?.id ? 'chats-list__feed--item-active' : ''}`}
                            onClick={() => onHandleClickSession(item)}
                            id={`session-item-${item?.id}`}
                        >
                            <Text
                                size="f14"
                                font={selectedIdSession === item?.id ? 'liteMedium' : 'book'}
                                color={selectedIdSession === item?.id ? 'black' : '#4A4A4A'}
                                className="chats-list__feed--item-text"
                            >
                                {item.summary || 'New chat'}
                            </Text>
                            {!item?.is_read ? <div className="chats-list__feed--item-badge"/> : null}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChatsList;