import React from 'react';
import Menu from "../../shared/features/Menu";
import Searcher from "../../shared/features/Searcher/Searcher";
import './Layout.scss';
import {Layout} from "antd";
import {useSelector} from "react-redux";
import {ExtensionBannerHeader} from "../Banner";
import {isCheckAuth} from "../../utils/helperFunctions";

const LayoutContainer = (props) => {
    const headerStyle = {
        background: '#F2F2F2',
        padding: 0,
        lineHeight: 'normal',
        height: 'auto'
    };

    const contentStyle = {
        // padding: '0 32px'
    };

    const siderStyle = {

        // backgroundColor: '#1677ff',
    };


    const layoutStyle = {
        // width: '100vw',
        height: '100vh'
    };


    // return (
        // <Layout style={layoutStyle}>
        //     <Layout.Sider width="25%" style={siderStyle}>
        //         <Menu/>
        //     </Layout.Sider>
        //     <Layout>
        //         <Layout.Header style={headerStyle}>Header</Layout.Header>
        //         <Layout.Content style={contentStyle}>{props.children}</Layout.Content>
        //         <Layout.Footer style={footerStyle}>Footer</Layout.Footer>
        //     </Layout>
        // </Layout>
    // )
    const {headerContainer} = props;
    const isFullWidth = useSelector(state => state.utils.isFullWidth);
    const isAuth = isCheckAuth();

    return (
        <Layout style={layoutStyle} >
            <Layout.Sider collapsed={!isFullWidth} width={isFullWidth ? 224 : 88}  collapsedWidth={88} style={siderStyle}>
                <Menu/>
            </Layout.Sider>
            <Layout>
                <Layout.Header style={headerStyle}>
                    {isAuth ?  <ExtensionBannerHeader/> : null}
                    {props?.isSearch ? <Searcher name={''}/> : null}
                    {props?.headerContainer ? props.headerContainer : null}
                    {/*{!props.headerHidden ?*/}
                    {/*    <>*/}
                    {/*        {props.headerContainer ? props.headerContainer : <Searcher name={''}/>}*/}
                    {/*    </>*/}
                    {/*    : null}*/}
                </Layout.Header>
                <Layout.Content style={contentStyle} className={props?.className}>{props.children}</Layout.Content>
                {/*<Layout.Footer style={footerStyle}>Footer</Layout.Footer>*/}
            </Layout>
        </Layout>
        // <div className="layout">
        //     <Menu/>
        //     <Searcher name={''}/>
        //     <div>sdsd</div>
        //     {children}
        // </div>
    );
};

export default LayoutContainer;