import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import './SettingsAutoChainsChannels.scss';
import {Accordion, useAccordionButton} from "react-bootstrap";
import {
	ArrowLeftIcon,
	ChainIcon,
	CloseStrokeIcon,
} from "../../../../../../image";
import {useSelector} from "react-redux";
import {CheckBox} from "../../../../../CheckBox";
import {
	AutoCainsSettingsContext,
	getChainsFromChannel,
	getSelectedChainsFromChannel,
	isCheckSelectChainsFromChannel
} from "../../helpers";
import ChainBlock from "../ChainBlock/ChainBlock";
import {Tooltip} from "antd";


const SettingsAutoChainsChannels = (props) => {
	const {event} = props;
	const {t} = useTranslation();
	const channels = useSelector(state => state.channels?.channels || []);
	const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];
	const [activeTabs, setActiveTabs] = React.useState([]);
	const allChains = useSelector(state => state.chains?.chainsList || []);
	const {settings, setSettings, autoChainsArr, updateAutoChains} = useContext(AutoCainsSettingsContext);

	useEffect(() => {
		const findChannelIndex = channels.findIndex(item => item.id === settings?.auto_chain_channel_id);
		setActiveTabs([...activeTabs, findChannelIndex])
	}, []);

	const selectAllChains = (isChecked, channel) => {
		const chains = getChainsFromChannel(allChains, channel).filter((item) => item?.name !== channel?.name);
		const aiChain = channel?.id === settings?.auto_chain_channel_id;
		const autoChainName = `Auto ${event?.summary}`.trim()
		const findAutoChain = channel.chains.find((item) => item.name === autoChainName);
		if(!findAutoChain){
			updateAutoChains(channel?.id, isChecked)
		}
		if(isChecked) {
			let obj = {
				...settings,
				chains_ids: findAutoChain ? [...settings.chains_ids, findAutoChain?.id] : [...settings.chains_ids],
				channels_ids: [...settings.channels_ids, channel.id],
			}


			if(aiChain) {
				obj = {
					...obj,
					need_auto_chain: isChecked
				}
			}
			setSettings(obj)
		} else {
			let obj = {
				...settings,
				channels_ids: settings.channels_ids.filter(item => item !== channel.id),
				chains_ids: settings.chains_ids.filter(item => !chains.map((item) => item.id).includes(item))
			}
			if(aiChain) {
				obj = {
					...obj,
					need_auto_chain: isChecked
				}
			}
			setSettings(obj)
		}
	}

	const deleteSelectedChains = (id) => {
		setSettings({
			...settings,
			chains_ids: settings.chains_ids.filter(item => item !== id)
		})
	}

	function CustomToggle({children, eventKey}) {
		const handleClickToggle = () => {
			if(activeTabs.includes(eventKey)) {
				setActiveTabs(activeTabs.filter(item => item !== eventKey));
			} else {
				setActiveTabs([...activeTabs, eventKey]);
			}
		}
		const decoratedOnClick = useAccordionButton(eventKey, handleClickToggle);

		return (
			<ArrowLeftIcon
				className="channel-block__container--arrow"
				onClick={decoratedOnClick}
			/>
		);
	}

	const renderAccordionChannel = ({data, index}) => {
		const checkIsChannel = settings?.channels_ids.includes(data?.id);

		return (
			<Accordion
				className={`${activeTabs.includes(index) ? 'active' : ''}`}
				activeKey={activeTabs}
				style={{
					'--chain_bg': data?.is_private ? 'rgba(26, 187, 120, 0.10)'
						: tagColors[index >= 5 ? index % 5 : index] + '14',
					'--chain_color': data?.is_private ? "#1ABB78" : tagColors[index >= 5 ? index % 5 : index],
					paddingBottom: data?.is_private ? '12px' : '0',
				}}
			>
				<Accordion.Item
					eventKey={index}
					style={{
						borderBottom: data?.is_private ? '0.7px solid #E6E6E6' : 'none',
					}}
				>
					<div className={`channel-block`}>
						<div className="channel-block__container">
							<CheckBox
								type={'checkbox'}
								onChange={(e) => selectAllChains(e?.target?.checked, data)}
								checked={data?.is_private
									|| checkIsChannel}
								disabled={data?.is_private}
							/>
							{(data?.name || '').length > 38 ?
								<Tooltip
									title={data?.name}
									placement="top"
								>
									<div className={`channel-block__container--badge`}>
										{data?.name}
									</div>
								</Tooltip>
								:
								<div className={`channel-block__container--badge`}>
									{data?.name}
								</div>
							}
							<CustomToggle eventKey={index} className="channel-block__container--arrow"/>
						</div>
						{!activeTabs.includes(index) ?
							<>

								{autoChainsArr.includes(data?.id) ?
									<div className="channel-block__chains" style={{marginBottom: '8px'}}>
										{(`Auto ${event?.summary}`).length > 34 ?
											<Tooltip
												title={`Auto ${event?.summary}`}
												placement="top"
											>
												<div className="channel-block__chains--item">
													<ChainIcon/>
													<span>{`Auto ${event?.summary}`}</span>
													<CloseStrokeIcon onClick={() => updateAutoChains(data?.id)}/>
												</div>
											</Tooltip>
											:
											<div className="channel-block__chains--item">
												<ChainIcon/>
												<span>{`Auto ${event?.summary}`}</span>
												<CloseStrokeIcon onClick={() => updateAutoChains(data?.id)}/>
											</div>
										}
									</div>
									: null}
								{getSelectedChainsFromChannel(allChains, data, settings.chains_ids).length ?
									<div className="channel-block__chains">
										{getSelectedChainsFromChannel(allChains, data, settings.chains_ids).map((item, index) => (
											<>
												{(item?.name || '').length > 34 ?
													<Tooltip
														title={item?.name}
														placement="top"
													>
														<div className="channel-block__chains--item" key={index}>
															<ChainIcon/>
															<span>{item?.name}</span>
															<CloseStrokeIcon onClick={() => deleteSelectedChains(item?.id)}/>
														</div>
													</Tooltip>
													:
													<div className="channel-block__chains--item" key={index}>
														<ChainIcon/>
														<span>{item?.name}</span>
														<CloseStrokeIcon onClick={() => deleteSelectedChains(item?.id)}/>
													</div>
												}
											</>
										))}
									</div>
									: null}
							</>
							:
							null
						}
					</div>
					<Accordion.Body>
						<ChainBlock channel={data} event={event}/>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		)
	}

	return (
		<div className="auto-chains-channels">
			<span className="auto-chains-channels--title">{t('manage_channels_and_chains')}</span>
			<div className="custom-scroll" style={{overflow: 'auto'}}>
				<div className="auto-chains-channels__content">
					{channels?.map((data, index) => (
						renderAccordionChannel({data, index})
					))}
				</div>
			</div>
		</div>
	);
};

export default SettingsAutoChainsChannels;