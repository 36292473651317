import {AUTH_CONSTS} from "../constants";

export const initialState = {
    token: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_CONSTS.LOG_IN:
            localStorage.setItem('jwtToken', action.payload.token);
            localStorage.setItem('tooltipChat', 'true');
            return {
                ...state,
                token: action.payload.token
            };
        case AUTH_CONSTS.LOG_OUT:
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('tooltipChat');
            return initialState;
        default:
            return state;
    }
}
