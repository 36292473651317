import axios from "axios";

import {store} from "../store";
import {LogOutActionCreator} from "../actions";


export const request = ({
                            url,
                            method = "GET",
                            data = null,
                            customHeaders,
                            signal
                        }) => {
    const customToken = localStorage.getItem('jwtToken');

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...customHeaders
    };
    customToken && (headers["Authorization"] = `Bearer ${customToken}`);
    const requestData = {
        url: url,
        method: method,
        headers: headers,
        signal
    };
    data && (requestData.data = data);
    return axios.request(requestData)
};

// log user out when token expires

axios.interceptors.response.use(response => response, error => {

        if (error.response && error.response.status === 401) {
           LogOutActionCreator();
           return error
        }
        return Promise.reject(error);
    }
);
