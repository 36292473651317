import React, {useEffect, useState} from 'react';
import './ConfluenseBlock.scss';
import {Text} from "../../../../modules";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../modules/Button";
import {useDispatch, useSelector} from "react-redux";
import InfoTooltip from "../../../Dashboard/containers/Profile/tooltip";
import {CheckBox} from "../../../../modules/CheckBox";
import {useCalendarActions} from "../../hooks";
import {GetConfluenceSpacesService, UpdateSpacesConfluenceService} from "../../../../redux/actions/integrationsActions";

const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];

const Info = {
    description: {
        ru: 'Интегрируйте Confluence, чтобы предоставить вашему ИИ-помощнику доступ к соответствующей документации и знаниям команды для более глубокого анализа совещаний.',
        en: 'Integrate Confluence to provide your AI assistant with access to relevant documentation and team knowledge for enhanced meeting insights.'
    },
    description_btn: {
        ru: 'Confluence еще не подключен. Нажмите «Подключиться», чтобы настроить его и начать работу.',
        en: 'Confluence isn\'t connected yet. Click "Connect" to set it up and get started.'
    }
}
const ConfluenceBlock = (props) => {
    const {t, i18n} = useTranslation();
    const {GetAuthorizationUrlConfluence, LogoutConfluence} = useCalendarActions();
    const [spaces, setSpaces] = useState();
    const confluenceInfo = useSelector(state => state.profile.confluenceInfo);

    const getSpaces = async () => {
        try {
            const response = await GetConfluenceSpacesService();
            setSpaces(response?.data?.[0])
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if(confluenceInfo?.token){
            getSpaces();
        }
    }, [confluenceInfo])


    async function checkChannel(e, value) {
        let obj = {
            ...spaces,
            spaces: spaces?.spaces.map((item) => {
                if (item.key === value) {
                    return {
                        ...item,
                        is_synchronized: e?.target?.checked,
                    }
                }
                return item
            })
        }
        setSpaces(obj);
        await UpdateSpacesConfluenceService({
            data: [obj]
        })
    }


    return (
        <div className="calendars-block">
            <Text font="medium" size="f18" className="calendars-block--title">Confluence</Text>
            {confluenceInfo?.token ?
                <>
                    <div className="calendars-block__info">
                        <div className="calendars-block__info--text">
                            {/*<Text size="f14" font="book" color="#9898A6">Confluence</Text>*/}
                            <Text size="f14" font="book" color="#9898A6"
                                  as="p"> {t('confluence_connected_sub_title')} </Text>
                            {/*<Text size="f14" font="book" color="#212121">kambulov@gmail.com</Text>*/}
                        </div>
                        <Button
                            text={t('calendar_settings_disconnect')}
                            onClick={LogoutConfluence}
                            variant="secondary"
                            color="red"
                            size="s"
                        />

                    </div>
                </>
                :
                <>
                    <div className="calendars-block__top">
                        <Text font="liteMedium" size="f16" color="#9898A6">{Info.description[i18n.language]}</Text>
                    </div>
                    <div className="calendars-block__bottom">
                        <Text font="liteMedium" size="f16" color="#9898A6">{Info.description_btn[i18n.language]}</Text>
                        <Button
                            text={t('connect')}
                            size="s"
                            onClick={GetAuthorizationUrlConfluence}
                        />
                    </div>
                </>
            }
            {confluenceInfo?.token ?
                <div className="calendars-block__body">
                    <Text font="medium" size="f16">{t('synced_spaces')}</Text>
                    <div className="calendars-block__body--list">
                        {(spaces?.spaces || [])
                            .map((data, index) => {
                                return (
                                    <CheckBox
                                        type={'checkbox'}
                                        checked={data?.is_synchronized}
                                        onChange={(e) => checkChannel(e, data.key)}
                                        label={
                                            <div className="label"
                                                 style={{
                                                     backgroundColor: tagColors[index >= 5 ? index % 5 : index] + '14',
                                                     color: tagColors[index >= 5 ? index % 5 : index],
                                                 }}
                                            >
                                                {data?.name?.length > 27 ? <InfoTooltip elem={data?.name}
                                                                                        title={data?.name}/> : data?.name}
                                            </div>
                                        }
                                    />
                                )
                            })}
                    </div>
                </div>
                : null}
        </div>
    );
};

export default ConfluenceBlock;