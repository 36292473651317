// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './roles.scss';
import {PlusGreenIcon} from "../../../../image";

// Import Components
import AddRole from "./modals/addRole/AddRole";
import ListItem from "./listItem/ListItem";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import UserInfoModal from "../Users/modals/userInfoModal/UserInfoModal";
import {useTranslation} from "react-i18next";
import {ChangeHeaderTitle, ClearRoles, DeleteRole, GetRoleById, GetRoles} from "../../../../redux/actions";
import config from "../../../../utils/config";
import {hasPermission, scrollTop} from "../../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../../utils/constants";
import {LayoutContainer} from "../../../../modules/Layout";

function Roles(props) {
    const [showPersonsModal, setShowPersonsModal] = useState(false)
    const [showAddRoleModal, setShowAddRoleModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [editedRole, setEditedRole] = useState(undefined)

    const {rolesList} = props

    const {t} = useTranslation();

    const isMax = config.productName.includes('max')

    useEffect(() => {
        scrollTop();
        props.ChangeHeaderTitle(t('roles_page_title'))
        props.GetRoles()
    }, [])

    function openModal() {
        setShowPersonsModal(true)
    }

    function closeModal() {
        setShowPersonsModal(false)
        setCurrentUser(null)
    }

    function openAddRoleModal() {
        setShowAddRoleModal(true)
    }

    function closeAddRoleModal() {
        setShowAddRoleModal(false)
    }

    return (
        <LayoutContainer isSearch>
            <div className={`main-container roles ${props.isFullWidth ? '' : 'full'}`}>
            <div className="roles__container">
                <div className="roles__container-wrap">
            <div className="roles__header">
                <div className="roles__header__right">
                    <div className="roles__title">{t('roles_page_title')}</div>
                </div>
                {hasPermission(ROLE_ITEMS.ROLES_CREATE) &&
                    <button className="roles__header__add-user" onClick={openAddRoleModal}>
                    <PlusGreenIcon/>
                        {t('add_role')}
                </button>}
            </div>

            <div className="roles__list-header">
                <div className="roles__list-header__content">
                    <div className="item_col">{t('table_role')}</div>
                    {isMax && <div className="item_col">{t('table_ldap')}</div>}
                    <div className="item_col">{t('table_persons')}</div>
                </div>
            </div>
            {
                !!rolesList?.length && rolesList?.map(item => {
                    return <ListItem key={`${item.person_id}_${item.user_id}`}
                                     data={item}
                                     isMax={isMax}
                                     openModal={openModal}
                                     setShowAddRoleModal={setShowAddRoleModal}
                                     DeleteRole={props.DeleteRole}
                                     setEditedRole={setEditedRole}
                    />
                })
            }

            {showPersonsModal && <UserInfoModal show={showPersonsModal}
                                                closeModal={closeModal}
                                                user={currentUser}
                                                videoPersonsList={props?.usersPersonsList?.results}/>}
            {showAddRoleModal && <AddRole show={showAddRoleModal}
                                          editedRole={editedRole}
                                          setEditedRole={setEditedRole}
                                          closeModal={closeAddRoleModal}
                                          GetRoleById={props.GetRoleById}
                                          roleById={props.roleById}
            />}
                </div>
            </div>
        </div>
        </LayoutContainer>
    );
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'personsList',
        'usersList',
        'rolesList',
        'roleById',
        'isFullWidth',
        'userRoles'
    ])
};

const mapDispatchToProps = {
    GetRoles, ChangeHeaderTitle, ClearRoles, DeleteRole, GetRoleById
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
