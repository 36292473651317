import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import {monthsShort, monthsShortEn} from "../../utils/constants";

export const preparedMessages = (defaultMessages, historyMessages, userInfo ,messagesArr = []) => {
	let messages = [...defaultMessages, ...historyMessages, ...messagesArr];

	let results = [];
	messages.forEach((item, index)=>{
		if(item?.request_text){
			results.push({
				id: results.length,
				isBot: false,
				text: item?.request_text,
				date: item?.request_date,
				used_db_query: false,
				author_name: `${userInfo?.last_name || ''} ${userInfo?.first_name || ''}`,
			})
		}

		if(item?.response_text){
			results.push({
				id: results.length,
				isBot: true,
				text: item?.response_text,
				date: item?.response_date,
				used_db_query: item?.used_db_query,
				author_name: 'Secretopus',
				tokens_used: item.tokens_used,
				sources: item?.sources || [],
			})
		}
	})
	return  results;
}

export const getTokensText = (item) => {
// Делим число на 1000
	let result = item / 1000;

	// Округляем до двух знаков после запятой
	return `${parseFloat(result.toFixed(2))} k. tokens `;
}

export const findUserText = (id, users, searchText, i18n) => {
	const findUser = users.find((item) => item.person_id === id);
	const text = (searchText || '').toLowerCase()
	let a = ''
	if(searchText){
		Object.keys(findUser || {}).forEach((key)=>{
			if(String((findUser?.first_name || '')).toLowerCase().includes(text)){
				a = `${i18n.language === 'ru' ? 'Имя:' : 'First name:'} ${findUser?.first_name}`
			}
			if(String((findUser?.last_name || '')).toLowerCase().includes(text)){
				a = `${i18n.language === 'ru' ? 'Фамилия:' : 'Last name:'} ${findUser?.last_name}`
			}
			if(String((findUser?.organization || '')).toLowerCase().includes(text)){
				a = `${i18n.language === 'ru' ? 'Организация:' : 'Organization:'} ${findUser?.organization}`
			}
			if(String((findUser?.position || '').toLowerCase()).includes(text)){
				a = `${i18n.language === 'ru' ? 'Должность:' : 'Position:'} ${findUser?.position}`
			}
			if((findUser?.signatures || []).some((item) => ((item || '').toLowerCase()).includes(text))){
				const findItem = (findUser?.signatures || []).find((item) => (item || '').toLowerCase().includes(text))
				a = `${i18n.language === 'ru' ? 'Подписи:' : 'Signatures:'} ${findItem}`
			}
			if((findUser?.tags || []).some((item) => ((item?.name || '').toLowerCase()).includes(text))){
				const findItem = (findUser?.tags || []).find((item) => (item?.name || '').toLowerCase().includes(text))
				a = `${i18n.language === 'ru' ? 'Теги:' : 'Tags:'} ${findItem?.name}`
			}
		})
	}

	return a;
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
function convertMillisecondsToTime(msDifference,i18n) {
	// 1 день = 24 часа = 1440 минут = 86400000 миллисекунд
	const days = Math.floor(msDifference / (1000 * 60 * 60 * 24));
	const hours = Math.floor((msDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((msDifference % (1000 * 60 * 60)) / (1000 * 60));

	return `${days > 0 ? `${days} ${i18n.language === 'ru' ? 'дн.' : 'd.'} ` : ''}${hours > 0 ? `${hours} ${i18n.language === 'ru' ? 'ч.' : 'h.'} ` : ''} ${minutes > 0 ? minutes : 0} ${i18n.language === 'ru' ? 'мин.' : 'm.'}  ${i18n.language === 'ru' ? 'назад' : 'ago'}`;
}
export function getTimeDifference(requestDate,i18n) {
	// Получаем текущую дату с учетом таймзоны пользователя
	const userTime = dayjs().tz(dayjs.tz.guess());

	// Преобразуем request_date в дату с учетом мск
	const requestDateMSK = dayjs.tz(requestDate, 'Europe/Moscow');

	// Вычисляем разницу во времени
	const difference = userTime.diff(requestDateMSK);

	// Форматируем разницу в удобочитаемый вид
	// const formattedDifference = dayjs.duration(difference).humanize(true);

	return convertMillisecondsToTime(difference,i18n);
}

export const getDateOpenChat = (date, i18n) => {
	const userTimeZone = dayjs.tz.guess(); // Определяем таймзону пользователя
	const data = dayjs(date).tz(userTimeZone); // Преобразуем дату под таймзону пользователя

	const monthIndex = data.month();
	const month = i18n.language === 'ru' ? monthsShort[monthIndex] : monthsShortEn[monthIndex];

	return `${data.date()} ${month} ${data.year()}, ${data.format('HH:mm:ss')}`
}

export const getEmptyText = (isVideoPage, i18n, videoName) => {
	const globalText = i18n.language === 'ru' ? 'Чатов пока нет. Нажмите кнопку "Начать чат", чтобы начать.' : 'No chats yet. Click the start chat button to begin.';
	const videoText = i18n.language === 'ru' ? 'Чатов пока нет. Нажмите кнопку "Начать чат", чтобы начать.' : 'No chats yet. Click the start chat button to begin.';
	return isVideoPage ? "" : globalText
}