import React, {useEffect, useRef, useState} from 'react';
import './protocol-item.scss';
import {getTimeString, isCheckAuth, parseTime, timeWithDots} from "../../../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import UserInfoModal from "../../../Users/modals/userInfoModal/UserInfoModal";
import {updateCheckboxesText, updateDetailsTagMarkdown} from "../../../../../../utils/markdown";
import {useDispatch, useSelector} from "react-redux";
import {history} from "../../../../../../utils/history";
import {ROUTERS_MAP} from "../../../../../../utils/routes";
import {ProtocolItemBlock} from "../../../../../../modules/Protocol";
import {SHOW_ADD_FORM_TYPES} from "../../../../../../modules/Protocol/helpers";
import {useDrag, useDrop} from "react-dnd";
import {MarkDownText} from "../../../../../../shared/features/MarkDownText";
import {FileStrokeIcon, StarIcon} from "../../../../../../image";
import {
    AddProtocol,
    ToggleCreateNewProtocolIdService,
    ToggleEditProtocolFlagService, UpdateProtocolPosition
} from "../../../../../../redux/actions";

const initProtocolData = {
    person_id: '',
    author_name: '',
    text: '',
    time: '',
    task_executor_person_id: '',
    task_executor_name: ''
}

function ProtocolItem({
                          addProtocolItem,
                          moveRow,
                          videoId,
                          UpdateProtocol,
                          protocol,
                          changeVideoTime,
                          videoPlayerCurrentTime,
                          prevProtocolId,
                          protocolIndex,
                          currentItem,
                          MarkVideo,
                          GetProtocol,
                          openFirstForm,
                          lastOrderRef,
                          items
                      }) {
    const [isEdit, setIsEdit] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [protocolData, setProtocolData] = useState(initProtocolData)
    const [protocolItem, setProtocolItem] = useState(protocol)
    const [showModal, setShowModal] = useState(false)
    const [userIdModal, setUserIdModal] = useState(false)
    const [isCheckClickBlock, setIsCheckClickBlock] = useState(false);

    const {t} = useTranslation();
    const ref = useRef();
    const profileInfo = useSelector(state => state.profile?.userProfile)
    const protocolItemPersonId = protocolItem?.person_id;
    const protocolAuthor = (protocolItem?.persons || []).find(item => item.id === protocolItemPersonId)
    const userProfile = useSelector(state => state.profile.userProfile);
    const protocolList = useSelector(state => state.protocol?.protocolList || []);

    const protocolExecutorAuthor = (protocolItem?.persons || []).find(item => item.id === protocolItem?.task_executor_person_id)
    const containerRef = useRef(null);
    const [text, setText] = useState(protocolItem?.text);
    const [openText, setOpenText] = useState(false);
    const isAuth = isCheckAuth();
    const [typeShowAddForm, setTypeShowAddForm] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        currentItem && setTimeout(() => {
            ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 100);
    }, [currentItem]);

    useEffect(() => {
        setProtocolItem(protocol)
    }, [protocol])


    useEffect(() => {
        document.addEventListener('click', closeNoteMenu);
        return () => {
            document.removeEventListener('click', closeNoteMenu)
        }
    }, []);

    const changeNoteData = (value) => {
        setText(value)
        setProtocolData({
            ...protocolData,
            text: value
        })
    }


    const getAuthorNameItem = () => {
        const {person_id, text, start_time, author_name, task_executor_name, task_executor_person_id} = protocolItem

        if (author_name) {
            return author_name
        } else if (protocolAuthor?.first_name) {
            return `${protocolAuthor.first_name ? protocolAuthor.first_name : ''} ${protocolAuthor?.last_name ? protocolAuthor?.last_name : ''}`
        } else if (protocolExecutorAuthor?.id) {
            return `id-${protocolExecutorAuthor?.id} ${t('unknown')}`
        } else if (!person_id) {
            return `${profileInfo?.first_name} ${profileInfo?.last_name}`
        }
        return ''
    }
    const toggleEdit = (e) => {
        e.stopPropagation()
        e.preventDefault()

        const {person_id, text, start_time, author_name, task_executor_name, task_executor_person_id} = protocolItem
        let timeValue = (start_time || protocol?.start_time !== null) ? getTimeString(start_time) : ''
        setProtocolData({
            ...protocolData,
            person_id: person_id ? person_id : profileInfo?.attached_person?.id || profileInfo?.perosn_id,
            text,
            author_name: getAuthorNameItem(),
            // task_executor_person_id,
            // task_executor_name: task_executor_name ? task_executor_name
            //     : protocolExecutorAuthor?.first_name
            //         ? `${protocolExecutorAuthor.first_name ? protocolExecutorAuthor.first_name : ''} ${protocolExecutorAuthor?.last_name ? protocolExecutorAuthor?.last_name : ''}`
            //         : protocolExecutorAuthor?.id && `id-${protocolExecutorAuthor?.id} ${t('unknown')}`,
            time: timeValue
        })
        // setShowNoteMenu(false)
        setShowForm(true)
        setIsEdit(true)
    }


    function toggleUserModal(person) {
        if (isAuth) {
            if (person && (userProfile?.attached_person?.id === person?.person_id || userProfile?.id === person?.user_id)) {
                history.push(ROUTERS_MAP.PROFILE);
            } else {
                setShowModal(!showModal)
                setUserIdModal(person)
            }

        }
    }

    const closeNoteMenu = () => {
        // setShowNoteMenu(false)
    }

    const addVideoProtocol = (text, flag = false) => {
        const {person_id, time, author_name, task_executor_name, task_executor_person_id} = protocolData

        let start_time = parseTime(time)
        let result = true

        if (!text) {
            result = false
        }


        if (result) {
            let reqData = {
                text,
            }

            if (!flag) {
                time && (reqData.start_time = start_time)
                videoId && (reqData.video_id = videoId)
                // person_id && (reqData.person_id = person_id)
                typeShowAddForm && (reqData.insert_after_protocol_block_id = (typeShowAddForm === SHOW_ADD_FORM_TYPES.DOWN ? protocol?.id : prevProtocolId))
                // task_executor_person_id && (reqData.task_executor_person_id = task_executor_person_id)
                if (!person_id && author_name) {
                    reqData.author_name = author_name ? author_name : null
                }
                // if (!task_executor_person_id && task_executor_name) {
                //     reqData.task_executor_name = task_executor_name
                // }

                addProtocolItem(reqData, protocolIndex)
            } else {
                // reqData.start_time = (time && time !== 0) ? start_time : 0
                reqData.start_time = !!start_time ? start_time : null

                // if (task_executor_person_id) {
                //     reqData.task_executor_person_id = task_executor_person_id
                //     reqData.task_executor_name = null
                // } else {
                //     reqData.task_executor_name = task_executor_name ? task_executor_name : null
                //     reqData.task_executor_person_id = null
                // }
                //
                // if (person_id) {
                //     reqData.person_id = person_id
                //     reqData.author_name = null
                // } else {
                //     reqData.author_name = author_name ? author_name : null
                //     reqData.person_id = null
                // }
                UpdateProtocol(protocolItem.id, reqData)
                MarkVideo(reqData)
                setProtocolItem({
                    ...protocolItem,
                    person_id,
                    author_name,
                    start_time: (time && time !== 0) ? start_time : 0,
                    text
                })
                setText(text)
            }

            setProtocolData(initProtocolData)
            setShowForm(false)
            setIsEdit(false)
        }
    }

    const closeEditing = () => {
        setShowForm(false)
        setIsEdit(false)
    }

    // Функция которая меняет при переключении заголовок кнопки
    useEffect(() => {
        const handleClick = (event) => {
            const detailsElement = event.target.closest('details');
            const summaryElement = event.target.closest('summary');

            const isOpen = detailsElement?.open;
            if (summaryElement) {
                if (isOpen) {
                    setOpenText(false);
                    summaryElement.textContent = 'Expand';
                } else {
                    setOpenText(true);
                    summaryElement.textContent = 'Collapse';
                }
            }
        };

        const currentContainer = containerRef.current?.querySelector('details summary');
        currentContainer?.addEventListener('click', handleClick);

        return () => {
            currentContainer?.removeEventListener('click', handleClick);
        };
    }, [containerRef, text]);
    const isEditProtocolId = useSelector((state) => state.protocol?.isEditProtocolId);

    useEffect(() => {
        const container = containerRef.current;

        if (!container) return;

        const handleClick = (event) => {
            const target = event.target;

            if (!isEditProtocolId && target.hasAttribute("personid")) {
                const personId = target.getAttribute("personid");
                if (personId && personId !== 'null') {
                    toggleUserModal({person_id: Number(personId)})
                }
            }
            if (!isEditProtocolId && target.hasAttribute("videoTime")) {
                // const videoTime = target.getAttribute("videoTime");
                const videoTime = target.textContent;
                if (videoTime && videoTime !== 'null') {
                    changeVideoTime(parseTime(videoTime), parseTime(videoTime));
                }
            }
            if (event.target.classList.contains('customSummary')) {
                const paragraph = container?.querySelector('.customParagraph');

                const isVisible = paragraph.getAttribute('data-visible') === 'true';
                // Переключаем значение атрибута data-visible
                if (isVisible) {
                    paragraph.setAttribute('data-visible', 'false');
                } else {
                    paragraph.setAttribute('data-visible', 'true');
                }
            }
        };
        const currentContainer = container?.querySelector('.tiptap-editor');

        if (currentContainer) {
            currentContainer.addEventListener("click", handleClick);
        }

        return () => {
            if (currentContainer) {
                currentContainer.removeEventListener("click", handleClick);
            }

        };
    }, [text]);

    const checkBoxClick = async (value, updateText) => {
        try {
            await UpdateProtocol(protocolItem.id, {
                author_name: value?.author_name,
                start_time: value?.start_time,
                text: updateText
            })
            await GetProtocol(videoId)
        } catch (e) {
            console.log(e)
        }
    }
    const openMenuRef = useRef(openText);

    useEffect(() => {
        openMenuRef.current = openText;
    }, [openText]);

    function convertToSeconds(timeString) {
        const timeParts = timeString.split(':').map(Number);
        const hours = timeParts.length === 3 ? timeParts[0] : 0;
        const minutes = timeParts.length >= 2 ? timeParts[timeParts.length - 2] : timeParts[0];
        const seconds = timeParts[timeParts.length - 1];

        return hours * 3600 + minutes * 60 + seconds;
    }


    useEffect(() => {
        const container =containerRef.current;
        if (!container) return;

        // Функция-обработчик клика
        const handleClick = (event) => {
            const target = event.target;

            if (!isAuth && target.tagName === "SPAN" && target.hasAttribute("data-person-id")) {
                const personId = target.getAttribute("data-person-id");
                if(personId && personId !== 'null'){
                    toggleUserModal(Number(personId))
                }
            }

            if (!isAuth && target.tagName === "SPAN" && target.hasAttribute("data-video-time")) {
                const time = target.getAttribute("data-video-time");
                if(time){
                    const seconds = convertToSeconds(time)
                    changeVideoTime(seconds,seconds)
                }
            }
        };

        const currentContainer = container?.querySelectorAll('.markdown-text-person');
        const currentTimeContainer = container?.querySelectorAll('.markdown-text-video-time');

        if(currentContainer.length){
            currentContainer.forEach(item => {
                item.addEventListener("click", handleClick);
            })
        }
        if(currentTimeContainer.length){
            currentTimeContainer.forEach(item => {
                item.addEventListener("click", handleClick);
            })
        }

        return () => {
            if(currentContainer.length){
                currentContainer.forEach(item => {
                    item.removeEventListener("click", handleClick);
                })
            }
            if(currentTimeContainer.length){
                currentTimeContainer.forEach(item => {
                    item.removeEventListener("click", handleClick);
                })
            }
        };
    }, [text]);


    // Обработка клика по чекбоксу в маркдауне
    // useEffect(() => {
    //     const handleChange = (event) => {
    //         console.log('item22',event)
    //         const label = event.target.parentElement;
    //         const item = label.parentElement;
    //         const isItem = item.hasAttribute("data-checked");
    //         console.log('item',isItem)
    //         if(isItem){
    //             const isChecked = item.getAttribute("data-checked") !== 'true';
    //             const test = item.getAttribute("data-checked");
    //             console.log('isChecked',isChecked)
    //             console.log('test',test)
    //             const foundText = item?.querySelector('div')?.outerText;
    //             handleClick(isChecked, foundText, text);
    //         }
    //
    //     };
    //
    //     const container = containerRef.current;
    //
    //     if (container) {
    //         const arrElements = container.querySelectorAll('.custom-checkbox333') || [];
    //         // Удаление старых обработчиков
    //         if (eventHandlerRef.current) {
    //             arrElements.forEach((item) => {
    //                 item.removeEventListener('change', eventHandlerRef.current);
    //             });
    //         }
    //
    //         // Сохранение ссылки на новый обработчик
    //         eventHandlerRef.current = handleChange;
    //
    //         // Добавление нового обработчика
    //         arrElements.forEach((item) => {
    //             item.addEventListener('change', eventHandlerRef.current); // передаем item через bind
    //         });
    //     }
    //
    //     // Очистка обработчиков при размонтировании компонента
    //     return () => {
    //         if (container) {
    //             const arrElements = container.querySelectorAll('.custom-checkbox333') || [];
    //             arrElements.forEach((item) => {
    //                 item.removeEventListener('change', eventHandlerRef.current);
    //             });
    //         }
    //     };
    // }, [protocolItem, text]);

    const [collectedProps, drop] = useDrop({
        accept: "dnd-character",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop: () => {
            // Вызываем API только после завершения DnD
            if (lastOrderRef.current) {
                dispatch(UpdateProtocolPosition(videoId, { order: lastOrderRef.current }));
            }
        },
        hover(item, monitor) {
            if (!containerRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = protocolIndex;

            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = containerRef.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [collectedDragProps, drag, preview] = useDrag({
        type: "dnd-character",
        item: () => {
            return {id: protocol?.id, index: protocolIndex};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        drag(drop(isCheckClickBlock ? containerRef : null));
    }, [isCheckClickBlock])

    if (!protocol?.id) {
        return <div/>
    }

    const addProtocolTypes = async (type) => {
        try{
            const lastProtocolId = protocolList.at(-1)?.id;
            const response = await dispatch(AddProtocol({
                video_id: videoId,
                text: '',
                insert_after_protocol_block_id: type === SHOW_ADD_FORM_TYPES.UP ? protocolList?.[protocolIndex - 1]?.id : (protocolList?.[protocolIndex]?.id || lastProtocolId)
            }, protocolList.length,true));
            dispatch(ToggleCreateNewProtocolIdService(response.protocol_text_id));
            dispatch(ToggleEditProtocolFlagService(response.protocol_text_id));
        }catch (e) {
            console.error(e)
        }
    }

    return (
        <div
            ref={containerRef}
            className={`${collectedDragProps.isDragging ? 'dragable' : ''}`}
        >
            {/*{typeShowAddForm === SHOW_ADD_FORM_TYPES.UP ?*/}
            {/*    <ProtocolItemBlock*/}
            {/*        text={''}*/}
            {/*        videoTime={videoPlayerCurrentTime}*/}
            {/*        onEdit={toggleEdit}*/}
            {/*        onCloseEdit={closeEditing}*/}
            {/*        isEdit={true}*/}
            {/*        openFirstForm={openFirstForm}*/}
            {/*        onChangeTypeShowForm={setTypeShowAddForm}*/}
            {/*        onSaveText={addVideoProtocol}*/}
            {/*        items={items}*/}
            {/*    />*/}
            {/*    : null}*/}
            {isAuth ?
                <ProtocolItemBlock
                    text={text}
                    videoTime={videoPlayerCurrentTime}
                    // onEdit={toggleEdit}
                    onCloseEdit={closeEditing}
                    isEdit={isEdit}
                    protocol={protocolItem}
                    openFirstForm={openFirstForm}
                    onChangeTypeShowForm={(type)=>{
                        addProtocolTypes(type);
                        setTypeShowAddForm(type)
                        // dispatch(ToggleEditProtocolFlagService(null))
                    }
                    }
                    onSaveText={addVideoProtocol}
                    updateClick={setIsCheckClickBlock}
                    items={items}
                />
                :
                <div
                    className={`protocol-item text ${ currentItem ? 'marked' : ''}`}
                >
                    <MarkDownText
                        text={text}
                        id={protocolItem?.id}
                    />
                    <div className='protocol-item__actions'>
                                {protocolItem?.marked ?
                                    <StarIcon
                                        className={`protocol-item__actions--start fill`}
                                    />
                                    :
                                    <StarIcon
                                        className={`protocol-item__actions--start protocol-block--hover`}
                                    />
                                }
                            <FileStrokeIcon className={`protocol-item__actions--file protocol-block--hover`}/>
                    </div>

                </div>
            }
            {/*{typeShowAddForm === SHOW_ADD_FORM_TYPES.DOWN ?*/}
            {/*    <ProtocolItemBlock*/}
            {/*        text={''}*/}
            {/*        videoTime={videoPlayerCurrentTime}*/}
            {/*        // onEdit={toggleEdit}*/}
            {/*        onCloseEdit={closeEditing}*/}
            {/*        openFirstForm={openFirstForm}*/}
            {/*        isEdit={true}*/}
            {/*        onChangeTypeShowForm={setTypeShowAddForm}*/}
            {/*        onSaveText={addVideoProtocol}*/}
            {/*        items={items}*/}
            {/*    />*/}
            {/*    : null}*/}
            {showModal && <UserInfoModal show={showModal}
                                         closeModal={toggleUserModal}
                                         user={userIdModal}
            />}
        </div>
    )
}

export default ProtocolItem;
