import {CHAT_CONSTS} from "../constants/chatConsts";

export const initialState = {
    isOpenChat: false,
    sessionsInfo: [],
    selectedIdSession: null,
    isGlobalChat: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHAT_CONSTS.UPDATE_OPEN_CHAT:
            return {
                ...state,
                isOpenChat: !state.isOpenChat,
            };
        case CHAT_CONSTS.GET_SESSIONS:
            return {
                ...state,
                sessionsInfo: action.payload,
            };
        case CHAT_CONSTS.UPDATE_SELECTED_ID_SESSION:
            return {
                ...state,
                selectedIdSession: action.payload,
            };
        case CHAT_CONSTS.UPDATE_GLOBAL_CHAT:
            return {
                ...state,
                isGlobalChat: action.payload,
            };
        default:
            return state;
    }
}