import { Mention } from "@tiptap/extension-mention";
import tippy from "tippy.js";
import { ReactRenderer } from "@tiptap/react";
import {deleteChar, TABS_ACTIONS_DROPDOWN} from "../../../helpers";
import PersonDropDown from "../../PersonDropDown/PersonDropDown";
import React from "react";

export default {
    char: '@', // Указываем триггер
    allowSpaces: true,

    render: () => {
        let reactRenderer;
        let popup;

        return {
            onStart: (props) => {
                if (!props.clientRect) {
                    return;
                }
                // Создаем рендер компонента EditorDropdownActions
                reactRenderer = new ReactRenderer(PersonDropDown, {
                    props:{
                        onClose: () =>popup[0].hide(),
                          ...props,
                    },
                    editor: props.editor,
                    type: 'popup',
                });

                // Настраиваем tippy.js popup для отображения списка
                    popup = tippy("body", {
                        getReferenceClientRect: props.clientRect,
                        content: reactRenderer.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: "manual",
                        placement: "bottom-start",
                        onHide: () => {
                            if (popup) {
                                // Убедитесь, что ссылка на попап удалена
                                popup = null;
                            }
                            deleteChar(props.editor, "@");
                            // props.editor.commands.focus(); // Фокус на редактор
                        }
                    });
            },
            onUpdate(props) {
                reactRenderer.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup?.[0].setProps({
                    getReferenceClientRect: props.clientRect,
                });
            },

            onKeyDown(props) {
                if (props.event.key !== 'ArrowUp' && props.event.key !== 'ArrowDown' && props.event.key !== 'Enter') {
                    popup?.[0]?.hide();
                    return reactRenderer.ref?.onKeyDown(props);
                }
                if (props.event.key === "Escape") {
                    popup[0].hide();
                    return reactRenderer.ref?.onKeyDown(props);
                }
                return reactRenderer.ref?.onKeyDown(props);
            },

            onExit() {
                popup?.[0]?.destroy();
                reactRenderer?.destroy();
            },
        };
    },
};
//
// // Расширение Mention с использованием suggestion
// export default Mention.extend({
//     addOptions() {
//         return {
//             ...this.parent?.(),
//             suggestion: mentionSuggestion,
//         };
//     },
// });
