import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Text} from "../../../../modules";
import {Button} from "../../../../modules/Button";
import {
	GoogleEmailIcon,
	GoogleMeetIcon,
	JiraIcon,
	OctopusIcon,
	OutlookCalendarIcon, OutlookEmailIcon, SlackDisabledIcon,
	TelegramIcon,
	СonfluenceIcon
} from "../../../../image";
import "./ServicesList.scss";
import {SERVICE_TYPES, SuccessModalContext} from "../../helpers";
import {useDispatch, useSelector} from "react-redux";
import {
	GetExtensionLinkService,
	GetUserInfoGoogleService
} from "../../../../redux/actions";
import {googleLogout, GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import {useCalendarActions} from "../../hooks";
import {detectExtensionPromise} from "../../../../utils/helperFunctions";
import {useLocation} from "react-router-dom";
import {
	GetGoogleEmailInfoService,
	GetGoogleEmailsListService,
	UpdateGoogleEmailInfoService
} from "../../../../redux/actions/integrationsActions";


const ServicesList = (props) => {
	const {activeTab, setActiveTab} = props;
	const {t} = useTranslation();
	const googleToken = useSelector(state => state.userCalendars?.googleToken);
	const googleUser = useSelector(state => state.userCalendars?.googleUserInfo);
	const outlookToken = useSelector(state => state.userCalendars.outlookInfo?.token?.access_token);
	const outlookInfo = useSelector(state => state.userCalendars.outlookInfo)
	const {handleOnSuccess,telegramLink,handleOnSuccessGoogleEmail} = useContext(SuccessModalContext);
	const dispatch = useDispatch()
	const userCalendars = useSelector(state => state.userCalendars);
	const {OutlookAuth, OutlookLogOut, GoogleLogOut,GetAuthorizationUrlConfluence,LogoutConfluence, DisconnectGoogleEmail} = useCalendarActions();
	const [link, setLink] = useState('');
	const [installExtension, setInstallExtension] = useState(false);
	const confluenceInfo = useSelector(state => state.profile.confluenceInfo);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const isStateQuery = searchParams.get('state');
	const isCodeQuery = searchParams.get('code');
	const googleEmailInfo = useSelector(state => state.userCalendars?.googleEmailInfo);
	const googleEmailsList = useSelector(state => state.userCalendars?.googleEmailsList);

	useEffect(()=>{
		if (isStateQuery && isCodeQuery) {
			setActiveTab(SERVICE_TYPES.WORKSPACE);
		}
	},[]);

	useEffect(() => {
		GetExtensionLinkService().then((res) => setLink(res?.link))
	}, [])

	const onCLickExtensionInstall = () => {
		window.open(link, '_blank');
	}

	const handleClickTelegramLinkBtn = () => {
		window.open(telegramLink, '_blank')
	}

	const detectExtension = async () => {
		const result = await detectExtensionPromise();
		setInstallExtension(result);
	}

	useEffect(() => {
		if(googleToken) {
			dispatch(GetUserInfoGoogleService(googleToken));
		}
	}, [googleToken])

	useEffect(()=>{
		detectExtension();
	},[])
	const clientId = '543483926394-hjdthh2g6o4qqb51dhlabsicpnn3q3o9.apps.googleusercontent.com'

	return (
		<div className="services">
			<div className="services__list">
				<div className="service">
					<Text size="f16" font="medium">{t('calendar_settings_calendar')}</Text>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.GOOGLE ? 'active' : ''}`}
						onClick={() => setActiveTab(SERVICE_TYPES.GOOGLE)}
					>
						<div className="service__item--container">
							<GoogleMeetIcon/>
							<div className="service__item--info">
								<Text size="f16" font="liteMedium">{t('calendar_settings_user_title')}</Text>
								{googleUser?.email ? <div className="service__item--info-text">
										<Text size="f14" font="book" color="#9898A6">{t('calendar_settings_user_name')} </Text>
										<Text size="f14"
											  font="book"
											  color="#212121">{googleUser?.email}
										</Text>
									</div>
									: null
								}
							</div>
							{googleToken ?
								<Button
									text={t('calendar_settings_disconnect')}
									variant="ghost"
									size="xs"
									color="red"
									onClick={GoogleLogOut}
								/>
								:
								<>
									{userCalendars?.clientId ?
										<GoogleOAuthProvider clientId={userCalendars?.clientId || ''}>
											<GoogleButton
												handleOnSuccess={handleOnSuccess}
												t={t}
											/>
										</GoogleOAuthProvider>
										: null}
								</>
							}
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('google_calendar_description')}
						</Text>
					</div>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.OUTLOOK ? 'active' : ''}`}
						onClick={() => setActiveTab(SERVICE_TYPES.OUTLOOK)}
					>
						<div className="service__item--container">
						<OutlookCalendarIcon/>
						<div className="service__item--info">
							<Text size="f16" font="liteMedium">Microsoft Outlook</Text>
							{(outlookInfo?.outlook_email && outlookToken) ?
								<div className="service__item--info-text">
									<Text size="f14" font="book" color="#9898A6">{t('calendar_settings_user_name')} </Text>
									<Text size="f14" font="book" color="#212121">{outlookInfo?.outlook_email}</Text>
								</div>
								: null}
						</div>
						{outlookToken ?
							<Button
								text={t('calendar_settings_disconnect')}
								variant="ghost"
								size="xs"
								color="red"
								onClick={OutlookLogOut}
							/>
							:
							<Button
								text={t('connect')}
								variant="ghost"
								size="xs"
								onClick={OutlookAuth}
							/>
						}
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('outlook_calendar_description')}
						</Text>
					</div>
				</div>
				<div className="service">
					<Text size="f16" font="medium">{t('email_accounts')}</Text>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.EMAIL ? 'active' : ''}`}
						onClick={() => setActiveTab(SERVICE_TYPES.EMAIL)}
					>
						<div className="service__item--container">
							<GoogleEmailIcon/>
							<div className="service__item--info">
								<Text size="f16" font="liteMedium">{t('calendar_settings_user_title')}</Text>
								{googleEmailInfo?.emails?.length ?
									<div className="service__item--info-text">
										<Text size="f14" font="book" color="#9898A6">{t('calendar_settings_user_name')} </Text>
										<Text size="f14" font="book" color="#212121">{googleEmailInfo?.emails?.length > 1 ? `${googleEmailInfo?.emails?.[0].email} +${googleEmailInfo?.emails?.length - 1}` : googleEmailInfo?.emails?.[0].email}</Text>
									</div>
									: null}
							</div>
							{googleEmailInfo?.emails?.length ?
								<Button
									text={t('calendar_settings_disconnect')}
									variant="ghost"
									size="xs"
									color="red"
									onClick={()=>{
										DisconnectGoogleEmail(googleEmailsList);
										dispatch(GetGoogleEmailInfoService())
									}}
								/>
								:
								// <div>f</div>
								<GoogleOAuthProvider clientId={userCalendars?.clientId}>
									<GoogleEmailLoginButton
										handleOnSuccess={handleOnSuccessGoogleEmail}
										t={t}
									/>
								</GoogleOAuthProvider>
							}
							{/*<Button*/}
							{/*	text={t('connect')}*/}
							{/*	variant="ghost"*/}
							{/*	size="xs"*/}
							{/*	// onClick={handleClickTelegramLinkBtn}*/}
							{/*/>*/}
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('google_mail_desc')}
						</Text>
					</div>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.OUTLOOK_EMAIL ? 'active' : ''}`}
						// onClick={() => setActiveTab(SERVICE_TYPES.OUTLOOK_EMAIL)}
					>
						<div className="service__item--container">
							<OutlookEmailIcon/>
							<Text size="f16" font="liteMedium">{t('outlook_mail')}</Text>
							<Button
								text={t('connect')}
								variant="ghost"
								size="xs"
								// onClick={handleClickTelegramLinkBtn}
							/>
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('outlook_mail_desc')}
						</Text>
					</div>
				</div>

				<div className="service">
					<Text size="f16" font="medium">{t('productivity_tools')}</Text>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.EXTENSION ? 'active' : ''}`}
						onClick={() => setActiveTab(SERVICE_TYPES.EXTENSION)}
					>
						<div className="service__item--container">
							<OctopusIcon className="service__item--secretopus"/>
							<Text size="f16" font="liteMedium">Secretopus Extension</Text>
							{installExtension ?
								<Button
									text={t('remove_extension')}
									variant="ghost"
									size="xs"
									color="red"
									onClick={onCLickExtensionInstall}
								/>
								:
								<Button
									text={t('install')}
									variant="ghost"
									size="xs"
									onClick={onCLickExtensionInstall}
								/>
							}
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('extension_description')}
						</Text>
					</div>
				</div>
				<div className="service">
					<Text size="f16" font="medium">{t('workspaces_collaboration')}</Text>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.WORKSPACE ? 'active' : ''}`}
						onClick={() => setActiveTab(SERVICE_TYPES.WORKSPACE)}
					>
						<div className="service__item--container">
							<СonfluenceIcon/>
							<Text size="f16" font="liteMedium">Confluence</Text>
							{confluenceInfo?.token ?
								<Button
									text={t('calendar_settings_disconnect')}
									variant="ghost"
									size="xs"
									color="red"
									onClick={LogoutConfluence}
								/>
								:
								<Button
									text={t('connect')}
									variant="ghost"
									size="xs"
									onClick={GetAuthorizationUrlConfluence}
								/>
							}
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('confluence_description')}
						</Text>
					</div>
				</div>
				<div className="service">
					<Text size="f16" font="medium">{t('chat_and_bot')}</Text>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.TELEGRAM ? 'active' : ''}`}
						onClick={() => setActiveTab(SERVICE_TYPES.TELEGRAM)}
					>
						<div className="service__item--container">
							<TelegramIcon/>
							<Text size="f16" font="liteMedium">Telegram</Text>
							<Button
								text={t('connect')}
								variant="ghost"
								size="xs"
								onClick={handleClickTelegramLinkBtn}
							/>
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('telegram_description')}
						</Text>
					</div>
				</div>

				<div className="service">
					<Text size="f16" font="medium">{t('soon')}</Text>
					<div className="service__item">
						<div className="service__item--container" style={{justifyContent: "flex-start"}}>
							<JiraIcon/>
							<Text size="f16" font="liteMedium" color="#828282">Jira</Text>
						</div>
					</div>
					<div className="service__item" >
						<div className="service__item--container" style={{justifyContent: "flex-start"}}>
							<SlackDisabledIcon/>
							<Text size="f16" font="liteMedium" color="#828282">Slack</Text>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function GoogleButton({handleOnSuccess, t}) {
	const login = useGoogleLogin({
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/calendar.readonly',
		onSuccess: async (codeResponse) => {
			handleOnSuccess(codeResponse)
		},
		onError: errorResponse => console.log(errorResponse),
	});
	return (
		<Button
			onClick={login}
			text={t('connect')}
			variant="ghost"
			size="xs"
		/>
	);
}

function GoogleEmailLoginButton({ handleOnSuccess, t }) {
	const dispatch = useDispatch();
	const login = useGoogleLogin({
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/gmail.readonly email https://www.googleapis.com/auth/userinfo.profile',
		onSuccess: async (codeResponse) => {
			await UpdateGoogleEmailInfoService({
				scope: codeResponse?.scope,
				code: codeResponse?.code
			});
			handleOnSuccess();
			setTimeout(()=>{
				dispatch(GetGoogleEmailInfoService());
				dispatch(GetGoogleEmailsListService());
			},1000)
		},
		onError: errorResponse => console.log(errorResponse),
	});

	return (
		<Button
			onClick={login}
			text={t('connect')}
			variant="ghost"
			size="xs"
		/>
	);
}

export default ServicesList;