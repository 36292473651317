import React, {useContext, useEffect, useState} from 'react';
import {Text} from "../../../../modules";
import './ExtensionBlock.scss';
import {useTranslation} from "react-i18next";
import {Button} from "../../../../modules/Button";
import {GetExtensionLinkService} from "../../../../redux/actions";
import {detectExtensionPromise} from "../../../../utils/helperFunctions";

const Info = {
    description: {
        ru:<>Установите расширение Secretopus, чтобы упростить проведение собраний. <br/> Контролируйте записи, получайте доступ к информации о предыдущих собраниях и делайте быстрые заметки.</>,
        en:<>Install the Secretopus Extension to streamline your meetings. <br/> Control recordings, access previous meeting insights, and take quick notes.</>
    },
    description_btn: {
        ru: 'Расширение еще не установлено. Нажмите "Установить", чтобы настроить его и начать экономить время.',
        en: 'The extension isn’t installed yet. Click "Install" to set it up and start saving time.'
    }
}
const InfoInstall = {
    description: {
        ru: 'Расширение Secretopus установлено',
        en: 'The Secretopus Extension is installed.'
    },
    description_btn: {
        ru: (
            <>
                Шаги по удалению расширения:
                <br/>
                <br/>
                Нажмите кнопку "Удалить расширение" выше.<br/>
                Вы будете перенаправлены на страницу магазина.<br/>
                Выберите "Удалить из Chrome" (или вашего браузера).<br/>
                Подтвердите удаление во всплывающем окне.<br/>
                <br/>
                <br/>
                Расширение будет успешно удалено из вашего браузера.
            </>
        ),
        en: (
            <>
                Steps to Remove the Extension:
                <br/>
                <br/>
                Click the "Remove Extension" button above.<br/>
                You will be redirected to the browser store page.<br/>
                Select "Remove from Chrome" (or your browser).<br/>
                Confirm removal in the popup window.<br/>
                <br/>
                <br/>
                The extension will be successfully removed from your browser.
            </>
        )
    }
}

const ExtensionBlock = () => {
    const {t,i18n} = useTranslation();
    const [install, setInstall] = useState(false);

    const detectExtension = async () => {
        const result = await detectExtensionPromise();
        setInstall(result);
    }

    const onClickInstall = () => {
        GetExtensionLinkService().then((res) => window.open(res?.link, '_blank'));
    }

    useEffect(() => {
        detectExtension()
    },[])

    return (
        <div className="extension-block">
            <Text font="medium" size="f18" className="calendars-block--title">Secretopus Extension</Text>
            <div className="extension-block__top">
                <Text font="liteMedium" size="f16" color="#9898A6">{install ? InfoInstall.description[i18n.language] : Info.description[i18n.language]}</Text>
                {install &&
                    <Button
                        text={t('remove_extension')}
                        size={'s'}
                        onClick={onClickInstall}
                        color="red"
                        variant="secondary"
                    />
                }
            </div>
            <div className="extension-block__body">
                <Text font="liteMedium" size="f16" color="#9898A6">{install ? InfoInstall.description_btn[i18n.language] : Info.description_btn[i18n.language]}</Text>
                {!install &&
                    <Button
                        text={t('install')}
                        size={'s'}
                        onClick={onClickInstall}
                    />
                }
            </div>
        </div>
    );
};

export default ExtensionBlock;